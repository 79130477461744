export const businessActionTypes = {
	SET_CONSULTATION_LIST: 'BUSINESS@SET_CONSULTATION_LIST',
	SET_TRIAL_APPLICATION_LIST: 'BUSINESS@SET_TRIAL_APPLICATION_LIST',
};

export default {
	setConsultationList: consultationList => ({
		type: businessActionTypes.SET_CONSULTATION_LIST,
		consultationList
	}),

	setTrialApplicationList: trialApplicationList => ({
		type: businessActionTypes.SET_TRIAL_APPLICATION_LIST,
		trialApplicationList
	}),
};