import {useEffect} from 'react';
import {notification} from 'antd';

export default function useNetwork (){

	useEffect(() => {
		const browserOffline = () => {
			notification.warning({
				message: '告警',
				description: '浏览器网络异常！'
			});
		};

		const browserOnline = () => {
			notification.success({
				message: '提示',
				description: '浏览器网络已恢复正常！'
			});
		};
		window.addEventListener('offline', browserOffline);
		window.addEventListener('online', browserOnline);

		return () => {
			window.removeEventListener('offline', browserOffline);
			window.removeEventListener('online', browserOnline);
		};
	}, []);
}