import Cookie from 'js-cookie';

export const ckSet = Cookie.set;

export const ckGet = Cookie.get;

export const ckRemove = Cookie.remove;

export const getStatusInCookie = key => {
    let val = ckGet(key);
    try {
        return JSON.parse(val || 'false');
    } catch (e) {
        return !!val;
    }
};