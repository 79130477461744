import {useEffect} from 'react';
import {Modal} from 'antd';

export default function useDestroyModal (){
	useEffect(() => {
		const browserHashChange = () => {
			// If user enter an another route path in the url input of browser, should
			// clear the modals he has opened before.
			Modal.destroyAll();
		};

		window.addEventListener('hashchange', browserHashChange);

		// Remove callbacks from browser online, offline status events.
		return () => {
			window.removeEventListener('hashchange', browserHashChange);
		};
	}, []);
}