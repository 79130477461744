const ceilYMax = (maxVal) => {
	// Optimize the y-axis label value for label splitting.
	if (!Number.isInteger(maxVal)) {
		maxVal = parseInt(maxVal);
	}
	let digits = maxVal.toString().length;
	let precision = Math.pow(10, digits - 1);
	return (parseInt(maxVal / precision) + 1) * precision;
};

export const yMaxNormal = params => {
	// If max is 0, yMax should be 1.
	if (params.max < 1) {
		return 1;
	}
	// In other cases, yMax should be double.
	return ceilYMax(parseInt(params.max * 2));
};

export const yMaxInt = params => {
	// Cause the default split number of y-axis is 5, so the interval val
	// at least should be 1, the yMax should be at least 5.
	if (params.max < 5) {
		return 5;
	} else {
		// In other cases, yMax should be double.
		return ceilYMax(parseInt(params.max * 2));
	}
};

export const yAxisLabelFormatterPercent = params => `${Number(params).toFixed(0)} %`;

export const tooltipFormatterPercent = params => (`
	${params[0].name} <br/>
	${params[0].seriesName}: ${Number(params[0].value).toFixed(1)} %
`);

export const tooltipFormatterInt = params => (`
	${params[0].name} <br/>
	${params[0].seriesName}: ${Number(params[0].value).toFixed(0)}
`);

export const makeLineSeries = (series) => {
	return series.map(series => {
		series['type'] = 'line';
		series['smooth'] = series.smooth || false;
		// series['showSymbol'] = false; // Only show symbol of mouse hovered data.
		series['symbol'] = 'none'; // Hide all symbol.
		series['symbolSize'] = 0;
		series['lineStyle'] = {
			width: 1,
		};
		return series;
	});
};

export const generateLineChartOption = ({title, legend, color, label, tooltipFormatter, x, y, yAxisLabelFormatter, yAxisUnit, yMin, yMax, series}) => {
	return {
		title: title,
		color: color,
		tooltip: {
			trigger: 'axis',
			showDelay: 20,
			hideDelay: 100,
			transitionDuration: 0.4,
			backgroundColor: 'rgba(100, 100, 100, .7)',
			borderColor: '#333',
			borderRadius: 4,
			borderWidth: 0,
			padding: 5,
			formatter: tooltipFormatter ? tooltipFormatter : null, // '{b} <br /> {a}: {c}',
			axisPointer: {
				type: 'line', // cross
				lineStyle: {
					color: '#b6b6b6',
					width: 1,
					type: 'solid'
				},
				shadowStyle: {
					width: 'auto',
					color: 'rgba(150, 150, 150, 0.3)'
				}
			},
			textStyle: {
				color: '#fff'
			},
		},
		legend,
		grid: {
			x: x,
			y: y,
			x2: 22,
			y2: 30
		},
		xAxis: [{
			type: 'category',
			boundaryGap: false,
			axisLine: {
				lineStyle: {
					color: '#b6b6b6'
				}
			},
			axisLabel: {
				formatter: '{value}',
				color: '#5f5f5f',
			},
			axisTick: {
				length: 3
			},
			splitLine: {
				show: true,
				lineStyle: {
					type: 'dashed',
					color: '#e5e5e5'
				}
			},
			splitArea: {show: false},
			data: label
		}],
		yAxis: [{
			type: 'value',
			min: yMin,
			max: yMax,
			axisLine: {
				lineStyle: {
					color: '#b6b6b6'
				}
			},
			axisLabel: {
				formatter: yAxisLabelFormatter ? yAxisLabelFormatter : ('{value}' + yAxisUnit),
				margin: 15,
				color: '#5f5f5f',
			},
			axisTick: {
				show: false
			},
			splitLine: {
				show: true,
				lineStyle: {
					type: 'dashed',
					color: '#e5e5e5'
				}
			},
			splitArea: {show: false},
			// Number of split segments.
			// Note: this is just a estimated value, the final number of segments actually displayed
			// will be automatically adjusted according to the readability of the coordinate axis scale
			// display after segmentation.
			splitNumber: 5,
		}],
		series,
	};
};