import {newsActionTypes} from '@actions/newsAction';

const newsReducer = (state, action) => {
    const {firmNewsList} = action;

    switch (action.type) {
        case newsActionTypes.SET_FIRM_NEWS_LIST:
            return Object.assign({}, state, {firmNewsList});

        default:
            return state;
    }
};

export default newsReducer;