import {useEffect, useRef} from 'react';
import PerfectScrollbar from 'perfect-scrollbar';

export default function useScrollbar (clsNameArr, options){
	// Ref data.
	const clsNameArrRef = useRef(null);
	const fsScrollbarArrRef = useRef(null);

	useEffect(() => {
		if (typeof clsNameArr === 'string') {
			clsNameArrRef.current = [clsNameArr];
		} else {
			clsNameArrRef.current = clsNameArr;
		}
		fsScrollbarArrRef.current = [];
		clsNameArrRef.current.forEach(clsName => {
			// The container DOM should have a position style, and make sure its
			// value is not 'static'. Because during the creation, it will create
			// two axes into the container, and their position values are 'absolute'.
			// Otherwise they will display outside of the container. Also the
			// container should be a normal container.
			const containerDOM = document.getElementsByClassName(clsName)[0];
			if (containerDOM) {
				let fsScrollbar = new PerfectScrollbar(containerDOM, {wheelSpeed: 2, ...(options || {})});
				fsScrollbar['containerClsName'] = clsName;
				fsScrollbarArrRef.current.push(fsScrollbar);
			}
		});

		return () => {
			fsScrollbarArrRef.current.forEach(scrollbar => {
				scrollbar.destroy();
				scrollbar = null;
			});
			fsScrollbarArrRef.current = null;
		};
	}, [clsNameArr, options]);
}