export default {
	general: {
		user: {
			id: '',
			fullname: '',
			username: '',
			roles: [],
			modules: [],
			phone: '',
			email: '',
			logins: 0,
			updateTime: null,
			createTime: '',
		},
		activeMenu: '',
		activePage: '',
	},

	dashboard: {
		overview: {
			certificates: {1: 0, 2: 0, 3: 0, 4: 0,},
			comments: {readed: 0, total: 0},
			customers: 0,
			documents: 0,
			jobs: {enabled: 0, total: 0},
			news: 0,
			products: 0,
			resumes:{readed: 0, total: 0},
			trials: {readed: 0, total: 0},
			users: 0,
		},
		statisticsPeriod: 'year',
		statistics: {
			vv: {
				data: [],
				time: [],
			},
			pv: {
				data: [],
				time: [],
			},
			ip: {
				data: [],
				time: [],
			},
			pages: [],
			customers: [],
		},
	},

	user: {
		CMSUserList: [],
		siteUserList: [],
	},

	news: {
		firmNewsList: [],
	},

	business: {
		consultationList: [],
		trialApplicationList: [],
	},

	product: {
		productList: [],
		productLatestList: [],
		documentList: [],
		feedbackListOfDocument: [],
		afterSalesCaseList: [],
		imageGalleryList: [],
		guaranteeList: [],
		certificateList: [],
	},

	storageQuote: {
		quoteList: [],
		source: {
			productList: [],
			productModelList: [],
			cpuVendorList: [],
			cpuList: [],
			memoryList: [],
			diskVendorList: [],
			diskList: [],
			networkCardVendorList: [],
			networkCardList: [],
			functionList: [],
			redundantList: [],
		 	consumableList: [],
			pricingFormulaList: [],
			/*
			productList: [
				{id: '1', name: 'UniIO', creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null,
					models: [
						{id: '1', name: 'x-1000', product: 'UniIO', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
						{id: '2', name: 'x-2000', product: 'UniIO', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
						{id: '3', name: 'x-3000', product: 'UniIO', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
					]
				},
				{id: '2', name: 'OrcaFS', creator: '管理员', createTime: '2020-11-11T08:11:23.748Z', updater: null, updateTime: null,
					models: [
						{id: '4', name: 'p-1000', product: 'OrcaFS', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
						{id: '5', name: 'p-2000', product: 'OrcaFS', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
						{id: '6', name: 'p-3000', product: 'OrcaFS', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
					]
				},
				{id: '3', name: 'OrcaBD', creator: '管理员', createTime: '2020-11-11T08:11:24.748Z', updater: null, updateTime: null,
					models: [
						{id: '7', name: 'h-1000', product: 'OrcaBD', price: 0, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
						{id: '8', name: 'h-2000', product: 'OrcaBD', price: 0,  creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
						{id: '9', name: 'h-3000', product: 'OrcaBD', price: 0,  creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
					]
				},
			],
			productModelList: [
				{id: '1', name: 'x-1000', product: 'UniIO', price: 30000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '2', name: 'x-2000', product: 'UniIO', price: 30000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '3', name: 'x-3000', product: 'UniIO', price: 30000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '4', name: 'p-1000', product: 'OrcaFS', price: 40000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '5', name: 'p-2000', product: 'OrcaFS', price: 40000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '6', name: 'p-3000', product: 'OrcaFS', price: 40000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '7', name: 'h-1000', product: 'OrcaBD', price: 20000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '8', name: 'h-2000', product: 'OrcaBD', price: 20000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
				{id: '9', name: 'h-3000', product: 'OrcaBD', price: 20000, creator: '管理员', createTime: '2020-11-11T08:11:21.748Z', updater: null, updateTime: null},
			],
			cpuVendorList: [
				{
					id: '1', name: '英特尔', creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null,
					cpus: [
						{id: '1', name: '至强W-3375 38核', price: 30000, vendor: '英特尔', productModels: ['x-3000@UniIO', 'p-3000@OrcaFS', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
						{id: '2', name: '至强E-2378 8核', price: 4000, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
						{id: '3', name: '至强Silver 4110 8核', price: 3600, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
						{id: '4', name: '至强Silver 4210 10核', price: 4100, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
						{id: '5', name: '至强Silver 4214 12核', price: 5600, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
						{id: '6', name: '至强Silver 4216 16核', price: 7500, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
					],
				},
				{
					id: '2', name: '飞腾', creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null,
					cpus: [
						{id: '7', name: 'FT-2000+ 64核', price: 50000, vendor: '飞腾', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
					],
				},
				{
					id: '3', name: '海光', creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null,
					cpus: [
						{id: '8', name: '1800x 8核', price: 2000, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
						{id: '9', name: '1900x 8核', price: 2500, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
						{id: '10', name: '1950x 12核', price: 3500, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
						{id: '11', name: '1950x 16核', price: 4500, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
					],
				},
			],
			cpuList: [
				{id: '1', name: '至强W-3375 38核', price: 30000, vendor: '英特尔', productModels: ['x-3000@UniIO', 'p-3000@OrcaFS', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '2', name: '至强E-2378 8核', price: 4000, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '3', name: '至强Silver 4110 8核', price: 3600, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '4', name: '至强Silver 4210 10核', price: 4100, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '5', name: '至强Silver 4214 12核', price: 5600, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '6', name: '至强Silver 4216 16核', price: 7500, vendor: '英特尔', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '7', name: 'FT-2000+ 64核', price: 50000, vendor: '飞腾', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '8', name: '1800x 8核', price: 2000, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '9', name: '1900x 8核', price: 2500, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '10', name: '1920x 12核', price: 3500, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
				{id: '11', name: '1950x 16核', price: 4500, vendor: '海光', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.748Z', updater: null, updateTime: null},
			],
			memoryList: [
				{id: '1', capacity: '64GB', price: 2000, type: 'DRAM', desc: '', productModels: ['p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '2', capacity: '128GB', price: 5000, type: 'DRAM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '3', capacity: '256GB', price: 15000, type: 'DRAM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '4', capacity: '512GB', price: 40000, type: 'DRAM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '5', capacity: '16GB', price: 2000, type: 'NVDIMM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '6', capacity: '32GB', price: 3500, type: 'NVDIMM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '7', capacity: '16GB', price: 4000, type: 'PMM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '8', capacity: '32GB', price: 4000, type: 'PMM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
				{id: '9', capacity: '64GB', price: 4000, type: 'PMM', desc: '', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:45:10.748Z', updater: null, updateTime: null},
			],
			diskVendorList: [
				{
					id: '1', name: '希捷', creator: '管理员', createTime: '2020-11-11T08:15:32.748Z', updater: null, updateTime: null,
					disks: [
						{id: '1', capacity: '960GB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'data', price: 8000, interfaceType: 'PCI-E NVMe', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '2', capacity: '1TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', price: 10000, interfaceType: 'PCI-E NVMe', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '3', capacity: '2TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', price: 15000, interfaceType: 'PCI-E NVMe', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '4', capacity: '3TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', price: 20000, interfaceType: 'PCI-E NVMe', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '5', capacity: '4TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', price: 35000, interfaceType: 'PCI-E NVMe',  productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '6', capacity: '6TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', price: 50000, interfaceType: 'PCI-E NVMe', productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
					],
				},
				{
					id: '2', name: '西部数据', creator: '管理员', createTime: '2020-11-11T08:15:33.148Z', updater: null, updateTime: null,
					disks: [
						{id: '7', capacity: '2TB', type: 'HDD', model: '400s', vendor: '西部数据', classification: 'data', interfaceType: 'SAS', price: 500, productModels: ['h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '8', capacity: '3TB', type: 'HDD', model: '400s', vendor: '西部数据', classification: 'data', interfaceType: 'SAS', price: 700, productModels: ['h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
					],
				},
				{
					id: '3', name: '英特尔', creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null,
					disks: [
						{id: '9', capacity: '960GB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 8000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '10', capacity: '1TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 10000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '11', capacity: '1.2TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 13000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '12', capacity: '1.92TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 20000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '13', capacity: '3.84TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 50000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '14', capacity: '7.68TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 110000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
					],
				},
				{
					id: '4', name: '三星', creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null,
					disks: [
						{id: '15', capacity: '960GB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 8000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '16', capacity: '1TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 10000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '17', capacity: '1.2TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 13000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '18', capacity: '1.92TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 20000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '19', capacity: '3.84TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 50000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '20', capacity: '7.68TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 110000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '21', capacity: '1TB', type: 'HDD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'SAS', price: 300, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
						{id: '22', capacity: '2TB', type: 'HDD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'SAS', price: 500, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
					],
				},
			],
			diskList: [
				{id: '1', capacity: '960GB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'data', interfaceType: 'PCI-E NVMe', price: 8000, productModels: ['x-1000@UniIO', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '2', capacity: '1TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', interfaceType: 'PCI-E NVMe', price: 12000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '3', capacity: '2TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', interfaceType: 'PCI-E NVMe', price: 15000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '4', capacity: '3TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', interfaceType: 'PCI-E NVMe', price: 20000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '5', capacity: '4TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', interfaceType: 'PCI-E NVMe', price: 35000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '6', capacity: '6TB', type: 'SSD', model: 'k-500', vendor: '希捷', classification: 'system', interfaceType: 'PCI-E NVMe', price: 50000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '7', capacity: '2TB', type: 'HDD', model: '400s', vendor: '西部数据', classification: 'data', interfaceType: 'SAS', price: 500, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '8', capacity: '3TB', type: 'HDD', model: '400s', vendor: '西部数据', classification: 'data', interfaceType: 'SAS', price: 700, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '9', capacity: '960GB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 8000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '10', capacity: '1TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 10000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '11', capacity: '1.2TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 13000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '12', capacity: '1.92TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 20000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '13', capacity: '3.84TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 50000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '14', capacity: '7.68TB', type: 'SSD', model: 'p4800x', vendor: '英特尔', classification: 'data', interfaceType: 'PCI-E NVMe', price: 110000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '15', capacity: '960GB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 8000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '16', capacity: '1TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 10000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '17', capacity: '1.2TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 13000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '18', capacity: '1.92TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 20000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '19', capacity: '3.84TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 50000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '20', capacity: '7.68TB', type: 'SSD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'PCI-E NVMe', price: 110000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '21', capacity: '1TB', type: 'HDD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'SAS', price: 300, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
				{id: '22', capacity: '2TB', type: 'HDD', model: 'h755', vendor: '三星', classification: 'data', interfaceType: 'SAS', price: 500, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD'], creator: '管理员', createTime: '2020-11-11T08:15:40.748Z', updater: null, updateTime: null,},
			],
			networkCardVendorList: [
				{id: '1', name: '英特尔', creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null,
					networkCards: [
						{id: '1', speed: '10GE', model: 'a-9091', interfaceType: 'RJ45', price: 2000, productModels: ['x-1000@UniIO', 'p-1000@OrcaFS', 'h-1000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
					]
				},
				{id: '2', name: '戴尔', creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null,
					networkCards: [
						{id: '2', speed: '20GE', model: 'x8766', interfaceType: 'SFP+', price: 4000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
						{id: '3', speed: '40GE', model: 'x8866', interfaceType: 'SFP+', price: 10000, productModels: ['x-2000@UniIO', 'p-2000@OrcaFS', 'h-2000@OrcaBD', 'x-3000@UniIO', 'p-3000@OrcaFS', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
					]
				},
			],
			networkCardList: [
				{id: '1', vendor: '英特尔', speed: '10GE', model: 'a-9091', interfaceType: 'RJ45', price: 2000, productModels: ['x-1000@UniIO', 'p-1000@OrcaFS', 'h-1000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '2', vendor: '戴尔', speed: '20GE', model: 'x8766', interfaceType: 'SFP+', price: 4000, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '3', vendor: '戴尔', speed: '40GE', model: 'x8866', interfaceType: 'SFP+', price: 10000, productModels: ['x-2000@UniIO', 'p-2000@OrcaFS', 'h-2000@OrcaBD', 'x-3000@UniIO', 'p-3000@OrcaFS', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
			],
			functionList: [
				{id: '1', name: '全局重删', price: 0, services: [], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '2', name: '告警监控', price: 0, services: [], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '3', name: '精简配置', price: 0, services: [], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '4', name: '集群监控', price: 0, services: [], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '5', name: 'QoS', price: 0, services: [], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '6', name: '快照', price: 0, services: ['块存储', '文件存储'], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '7', name: '克隆', price: 0, services: ['块存储'], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '8', name: 'CIFS共享', price: 0, services: ['文件存储'], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '9', name: 'NFS共享', price: 0, services: ['文件存储'], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '10', name: 'Bucket', price: 0, services: ['对象存储'], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '11', name: '对象网关', price: 0, services: ['对象存储'], isDefault: true, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '12', name: '远程复制', price: 20000, services: ['块存储'], isDefault: false, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '13', name: '文件系统检查', price: 30000, services: ['文件存储'], isDefault: false, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '14', name: '对象高级功能', price: 30000, services: ['对象存储'], isDefault: false, productModels: ['h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
			],
			consumableList: [
				{id: '1', name: 'RJ45水晶头一盒', price: 100, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '2', name: '超六类网线10M', price: 100, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO', 'p-1000@OrcaFS', 'p-2000@OrcaFS', 'p-3000@OrcaFS', 'h-1000@OrcaBD', 'h-2000@OrcaBD', 'h-3000@OrcaBD',], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
				{id: '3', name: 'RAID卡一块', price: 3000, productModels: ['x-1000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:15:34.648Z', updater: null, updateTime: null},
			],
			pricingFormulaList: [
				{id: '1', name: 'UniIO2.0报价公式', pricePerTB: 2000, coefficientPercent: 0.9, discount: 0.9, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:40:10.448Z', updater: null, updateTime: null},
				{id: '2', name: 'UniIO3.0报价公式', pricePerTB: 1000, coefficientPercent: 0.85, discount: 0.95, productModels: ['x-1000@UniIO', 'x-2000@UniIO', 'x-3000@UniIO'], creator: '管理员', createTime: '2020-11-11T08:42:10.218Z', updater: null, updateTime: null},
			],
			 */
		},
		quoteDiscountApplyList: [],
	},

	recruitment: {
		jobList: [],
		jobApplicationList: [],
	},

	log: {
		operationLogList: [],
	},
};