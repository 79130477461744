export const roleMap = {
	admin: '超级管理员',
	news: '资讯管理员',
	product: '产品组管理员',
	storageQuote: '产品报价单管理员',
	storageQuoteSource: '产品报价源管理员',
	storageQuoteHighDiscountApprover: '产品报价单折扣申请审批员（高折扣）',
	storageQuoteLowDiscountApprover: '产品报价单折扣申请审批员（低折扣）',
	hr: 'HR组管理员',
};

export const capacityUnitSizeMap = {
	EB: 1024 * 1024 * 1024 * 1024 * 1024 * 1024,
	PB: 1024 * 1024 * 1024 * 1024 * 1024,
	TB: 1024 * 1024 * 1024 * 1024,
	GB: 1024 * 1024 * 1024,
	MB: 1024 * 1024,
	KB: 1024,
	B: 1,
};

export const spqDiscountApplyStatusMap = {
	submitted: '未处理',
	cancelled: '被取消',
	revoked: '被撤回/撤销',
	approved: '已通过',
	failed: '被驳回',
	rescinded: '被废除',
};

export const spqDiscountApplyReasonMap = {
	'submitted': '该折扣申请在等待审批中',
	'cancelled by update': '该折扣申请所属的产品报价单在申请等待审批时有过被编辑的操作，该申请已作废',
	'cancelled by delete': '该折扣申请所属的产品报价单已被删除，该申请已作废',
	'cancelled by resubmit': '该折扣申请在未被审批前，其所属的产品报价单又创建了新的申请，该申请已作废',
	'approved': '该折扣申请已通过审批，折扣已生效',
	'failed': '该折扣申请被驳回，没有通过审批',
	'revoked': '等待审批的折扣申请或已通过审批的折扣被某产品报价单管理员手动撤回或撤销，该申请已作废',
	'rescinded': '该折扣申请在通过审批后被审批管理员手动废除，该申请已作废',
};