import {useCallback, useRef} from 'react';

export default function useConfig (){
	const getPopupContainer = useCallback((triggerNode) => triggerNode.parentNode, []);

	const configRef = useRef({
		buttonConf: {
			size: 'small',
			shape: 'circle',
			style: {
				marginRight: 5,
			}
		},
		buttonPopoverConf: {
			mouseEnterDelay: 0.3,
			mouseLeaveDelay: 0,
		},
		getPopupContainer,
		tipsStyle: {
			fontSize: 12,
			marginTop: 15,
		},
		textAreaSize: {
			minRows: 4,
			maxRows: 6,
		},
		switchStyle: {
			marginRight: 10,
		},
		editorConf: {
    		height: 400,
   		 	contentFormat: 'html',
		 	initialContent: '',
			style: {
    			border: '1px solid #d9d9d9',
				borderRadius: '6px',
			},
			excludeControls: ['emoji', 'code'],
		},
	});

	return configRef.current;
}