import React, {useMemo} from 'react';
import {Icon, Spin} from 'antd';

export default function OCMSPlaceholder (){
    const style = useMemo(() => ({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: '#fff',
    }), []);

    return (
        <div style={style}>
            <Spin indicator={<Icon type="loading" spin />} />
        </div>
    );
}