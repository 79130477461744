import React, {useCallback, useEffect, useMemo, useState, useRef} from 'react';
import {Icon, Table, Popover, Spin} from 'antd';
import {Resizable} from 'react-resizable';

// Merge pagination conf.
function useMergeDefaultPagination (pagination){
	const defaultPaginationMemo = useMemo(() => ({
		hideOnSinglePage: true,
		pageSize: 15,
		showTotal: (total, range) => (
			<>
				<Popover content="数据持续刷新中">
					<Icon type="sync" spin />
				</Popover>
				<Popover content={`显示 ${range[0]}-${range[1]} 项数据，共 ${total} 项`}>
					<span className="ocms-hover-default">{range[0]}-{range[1]} / {total}</span>
				</Popover>
			</>
		),
		size: 'normal'
	}), []);

	return useMemo(() => {
		if (pagination === undefined) {
			// Pagination is not passed, provide the default conf.
			return defaultPaginationMemo;
		} else {
			if (pagination === true) {
				// Pagination is passed, but without a certain conf, provide the default conf.
				return defaultPaginationMemo;
			} else if (pagination === false) {
				// Pagination is explicitly passed as false, disable the pagination bar.
				return false;
			} else {
				// Pagination is passed, provide a merged conf.
				return Object.assign({}, defaultPaginationMemo, pagination);
			}
		}
	}, [defaultPaginationMemo, pagination]);
}

// Resizable table header component.
const ResizableTableHeaderCell = props => {
	const {onResize, width, ...restProps} = props;
	return !!width ? (
			<Resizable
				width={width}
				height={0}
				draggableOpts={{enableUserSelectHack: false}}
				onResize={onResize}
			>
				<th {...restProps} />
			</Resizable>
		) :
		(<th {...restProps} />);
};

// Table header cell resize event handler, through curry and memorized.
function useCellResize (setColumns, resizableRange){
	return useCallback((dataIndex) => (e, {size}) => {
		let [minWidth, maxWidth] = resizableRange;
		let width = size.width;

		if (width < minWidth) {
			width = minWidth;
		}

		if (width > maxWidth) {
			width = maxWidth;
		}

		// Set new width for resizable column and trigger table re-render.
		setColumns(columns => {
			let newColumns = [...columns];
			let resizableColumn = newColumns.find(column => column.dataIndex === dataIndex);
			resizableColumn.width = width;
			return newColumns;
		});
	}, [setColumns, resizableRange]);
}

// Make special columns resizable.
function makeColumnsResizable (resizableColumns, columns, cellResize){
	columns = [...columns];

	return resizableColumns.reduce((prevColumns, dataIndex) => {
		let column = prevColumns.find(column => column.dataIndex === dataIndex);
		let originRes = null;
		if (typeof column.onHeaderCell === 'function') {
			originRes = column.onHeaderCell(column);
		}
		column.ellipsis = true;
		column.onHeaderCell = column => {
			const res = {
				width: column.width,
				onResize: cellResize(column.dataIndex),
			};
			return originRes ? Object.assign({}, originRes, res) : res;
		};
		return prevColumns;
	}, columns);
}

export default function useTable (tableArgs){
	// Parse args.
	const defaultColumnsRef = useRef([]);

	const defaultResizableRangeRef = useRef([40, 500]);

	let {
		size = 'normal',
		title,
		emptyText = '暂无数据',
		dataSource,
		rowKey,
		columns: argsColumns = defaultColumnsRef.current,
		rowSelection = undefined,
		pagination,
		onRow,
		loading,
		scroll,
		// Resizable extra.
		resizableColumns = null, // [['name', 140], ['desc', '250']]
		resizableRange = defaultResizableRangeRef.current,
	} = tableArgs;

	// Call setColumns is the re-render trigger, it is important.
	const [columns, setColumns] = useState(argsColumns);

	useEffect(() => {
		// If columns is changed outside of this hook, should reset it to
		// trigger table re-render.
		setColumns(tableArgs.columns);
	}, [tableArgs.columns]);

	// Row selection.
	rowSelection = useMemo(() => !rowSelection ? undefined : rowSelection, [rowSelection]);

	// Pagination.
	pagination = useMergeDefaultPagination(pagination);

	const cellResize = useCellResize(setColumns, resizableRange);

	// Generate props for table.
	const basePropsMemo = useMemo(() => ({
		size,
		title: title && (() => title),
		dataSource,
		rowKey,
		locale: {
			emptyText
		},
		rowSelection,
		pagination,
		onRow,
		scroll,
	}), [size, title, dataSource, rowKey, emptyText, rowSelection, pagination, onRow, scroll]);

	const resizablePropsMemo = useMemo(() => {
		let newColumns = [];
		let components = undefined;

		if (resizableColumns) {
			// Resizable is enabled.
			newColumns = makeColumnsResizable(resizableColumns, columns, cellResize);
			components = {
				header: {
					cell: ResizableTableHeaderCell,
				},
			};
		} else {
			newColumns = columns;
		}

		return {
			columns: newColumns,
			components,
		};
	}, [resizableColumns, columns, cellResize]);

	const tablePropsMemo = useMemo(() => Object.assign({}, basePropsMemo, resizablePropsMemo), [basePropsMemo, resizablePropsMemo]);

	const tableMemo = useMemo(() => (<Table {...tablePropsMemo} />), [tablePropsMemo]);

	// If we directly use the loading prop in tableProps to show a loading state, it may be
	// frequently changed, so the tableMemo will frequently changed too without any data change.
	// So we manually use a Spin to wrap the tableMemo and use it to control the loading state.
	return useMemo(() => (
		loading !== void 0 ?
			(
				<Spin
					spinning={loading}
					indicator={<Icon type="loading" spin />}
				>
					{tableMemo}
				</Spin>
			) :
			tableMemo
	), [loading, tableMemo]);
}