import {storageQuoteActionTypes} from '@actions/storageQuoteAction';

const storageQuoteReducer = (state, action) => {
	const {quoteList, productList, productModelList, cpuVendorList, cpuList, memoryList, diskVendorList, diskList, networkCardVendorList, networkCardList, functionList, consumableList, pricingFormulaList, quoteSource, quoteDiscountApplyList} = action;

	switch (action.type) {
		case storageQuoteActionTypes.SET_QUOTE_LIST:
			return Object.assign({}, state, {quoteList});

		case storageQuoteActionTypes.SET_PRODUCT_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {productList})});

		case storageQuoteActionTypes.SET_PRODUCT_MODEL_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {productModelList})});

		case storageQuoteActionTypes.SET_CPU_VENDOR_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {cpuVendorList})});

		case storageQuoteActionTypes.SET_CPU_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {cpuList})});

		case storageQuoteActionTypes.SET_MEMORY_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {memoryList})});

		case storageQuoteActionTypes.SET_DISK_VENDOR_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {diskVendorList})});

		case storageQuoteActionTypes.SET_DISK_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {diskList})});

		case storageQuoteActionTypes.SET_NETWORK_CARD_VENDOR_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {networkCardVendorList})});

		case storageQuoteActionTypes.SET_NETWORK_CARD_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {networkCardList})});

		case storageQuoteActionTypes.SET_FUNCTION_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {functionList})});

		case storageQuoteActionTypes.SET_CONSUMABLE_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {consumableList})});

		case storageQuoteActionTypes.SET_PRICING_FORMULA_LIST:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {pricingFormulaList})});

		case storageQuoteActionTypes.SET_QUOTE_SOURCE:
			return Object.assign({}, state, {source: Object.assign({}, state.source, {
				productList: quoteSource.products,
				productModelList: quoteSource.productModels,
				cpuVendorList: quoteSource.cpuVendors,
				cpuList: quoteSource.cpus,
				memoryList: quoteSource.rams,
				diskVendorList: quoteSource.diskVendors,
				diskList: quoteSource.disks,
				networkCardList: quoteSource.networkCards,
				functionList: quoteSource.functions,
				consumableList: quoteSource.consumables,
				pricingFormulaList: quoteSource.pricingFormulas,
			})});

		case storageQuoteActionTypes.SET_QUOTE_DISCOUNT_APPLY_LIST:
			return Object.assign({}, state, {quoteDiscountApplyList});

		default:
			return state;
	}
};

export default storageQuoteReducer;