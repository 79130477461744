import LOGO_PNG from '@images/logo_color.png';

const {NODE_ENV, NAME, VERSION, BUILD_TIME, BUILD_ENV} = process.env;

const versionLabel = '版本';

const envLabel = '环境';

const buildTimeLabel = '打包时间';

const buildEnvLabel = '打包环境';

const logoStyle = `
	padding: 23px 70px;
	width: 67px;
	height: 23px;
	background-image: url(${LOGO_PNG});
	background-size: 100% 100%;
	background-repeat: no-repeat;
`;

const labelStyle = `
	padding: 2px;
	color: #fff;
	background: #5d5d5d;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
`;

const valueStyle = `
	padding: 2px;
	color: #fff;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
`;

const versionValueStyle = `
	background: #06d2a4;
	${valueStyle}
`;

const envValueStyle = `
	background: #8cba05;
	${valueStyle}
`;

const buildTimeValueStyle = `
	background: #127ebe;
	${valueStyle}
`;

const buildEnvValueStyle = `
	background: #7c70da;
	${valueStyle}
`;

export const printProjectInfo = () => {
	// Print project information on browser console.
	try {
		console.log("%c   ", logoStyle);
		console.log(`%c GUI ${versionLabel} %c ${NAME}@${VERSION} `, labelStyle, versionValueStyle);
		console.log(`%c GUI ${envLabel} %c ${NODE_ENV} `, labelStyle, envValueStyle);
		console.log(`%c GUI ${buildTimeLabel} %c ${BUILD_TIME} `, labelStyle, buildTimeValueStyle);
		console.log(`%c GUI ${buildEnvLabel} %c ${BUILD_ENV} `, labelStyle, buildEnvValueStyle);
	} catch (e){
		// If the browser console does not support to display a styled text, then fall back to a plain text.
		console.info(`GUI ${versionLabel}:`, `${NAME}@${VERSION}`);
		console.info(`GUI ${envLabel}:`, `${NODE_ENV}`);
		console.info(`GUI ${buildTimeLabel}:`, `${BUILD_TIME}`);
		console.info(`GUI ${buildEnvLabel}:`, `${BUILD_ENV}`);
	}

	// Print warning tips on browser console.
	if (NODE_ENV === 'production') {
		console.info('警告：为了本CMS系统的稳定运行和数据的安全，如果您非本系统的运维人员，请勿在开发者工具里执行任何操作！');
	}
};
