export const formatBatchNames = (batchNameArr, count = 5, splitter = ', ') => {
	if (batchNameArr.length > count) {
		let sliceNames = batchNameArr.slice(0, count);
		return {
			rawLength: batchNameArr.length,
			cutLength: count,
			names: `${sliceNames.join(splitter)}, ...等`,
		};
	}

	return {
		rawLength: batchNameArr.length,
		names: batchNameArr.join(splitter),
	};
};

export const splitCapacity = (sizeStr) => {
	// Split 256GB to {capacityNumber: 256, capacityUnit: 'GB'}.
	let res = sizeStr.match(/B|K|M|G|T|P|E/);
	if (res) {
		let capacityUnit = sizeStr.slice(res.index);
		let capacityNumber = Number(sizeStr.replace(capacityUnit, ''));
		return {capacityNumber, capacityUnit};
	} else {
		return {capacityNumber: 0, capacityUnit: 'GB'};
	}
};

export const percentToNumber = (percentStr) => Number(percentStr.replace('%', '')) / 100;

export const percentNumberToNumber = (percentNumber) => Number((percentNumber / 100).toFixed(2));

export const numberToPercent = (number) => number * 100 + '%';

export const numberToPercentNumber = (number) => parseInt(number * 100);

export const formatTime = (date, fmt = 'yyyy-MM-dd hh:mm:ss') => {
	if (!date) return '--';
	let dateOrigin = new Date(date);
	let o = {
		"M+" : dateOrigin.getMonth() + 1,
		"d+" : dateOrigin.getDate(),
		"h+" : dateOrigin.getHours(),
		"m+" : dateOrigin.getMinutes(),
		"s+" : dateOrigin.getSeconds(),
		"q+" : Math.floor((dateOrigin.getMonth() + 3) / 3),
		"S"  : dateOrigin.getMilliseconds()
	};
	if (/(y+)/.test(fmt)) {
		fmt = fmt.replace(RegExp.$1, String(dateOrigin.getFullYear()).substr(4 - RegExp.$1.length));
	}
	for (let k in o) {
		if (new RegExp("("+ k +")").test(fmt)) {
			fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)));
		}
	}
	return fmt;
};

export const getDiscountText = (discount) => discount * 10 + ' 折';
