export const productActionTypes = {
	SET_PRODUCT_LIST: 'PRODUCT@SET_PRODUCT_LIST',
	SET_PRODUCT_LATEST_LIST: 'PRODUCT@SET_PRODUCT_LATEST_LIST',
	SET_DOCUMENT_LIST: 'PRODUCT@SET_DOCUMENT_LIST',
	SET_FEEDBACK_LIST_OF_DOCUMENT: 'PRODUCT@SET_FEEDBACK_LIST_OF_DOCUMENT',
	SET_IMAGE_GALLERY_LIST: 'PRODUCT@SET_IMAGE_GALLERY_LIST',
	SET_AFTER_SALES_CASE_LIST: 'PRODUCT@SET_AFTER_SALES_CASE_LIST',
	SET_CERTIFICATE_LIST: 'PRODUCT@SET_CERTIFICATE_LIST',
	SET_GUARANTEE_LIST: 'PRODUCT@SET_GUARANTEE_LIST',
};

export default {
	setProductList: productList => ({
		type: productActionTypes.SET_PRODUCT_LIST,
		productList
	}),

	setProductLatestList: productLatestList => ({
		type: productActionTypes.SET_PRODUCT_LATEST_LIST,
		productLatestList
	}),

	setDocumentList: documentList => ({
		type: productActionTypes.SET_DOCUMENT_LIST,
		documentList
	}),

	setFeedbackListOfDocument: feedbackListOfDocument => ({
		type: productActionTypes.SET_FEEDBACK_LIST_OF_DOCUMENT,
		feedbackListOfDocument
	}),

	setImageGalleryList: imageGalleryList => ({
		type: productActionTypes.SET_IMAGE_GALLERY_LIST,
		imageGalleryList
	}),

	setAfterSalesCaseList: afterSalesCaseList => ({
		type: productActionTypes.SET_AFTER_SALES_CASE_LIST,
		afterSalesCaseList
	}),

	setCertificateList: certificateList => ({
		type: productActionTypes.SET_CERTIFICATE_LIST,
		certificateList
	}),

	setGuaranteeList: guaranteeList => ({
		type: productActionTypes.SET_GUARANTEE_LIST,
		guaranteeList
	}),
};