import {ckRemove, lsGet, lsSet, lsRemove, deleteURLQuery, restructureURLQuery, reloadWithoutCache} from '@utils';

const {BUILD_TIME, NODE_ENV} = process.env;

const recordBuildTime = (clearCookie) => {
	// Set the latest build time to local storage for next check.
	lsSet('ocms_buildTime', BUILD_TIME);

	if (clearCookie) {
		ckRemove('ocms_sessionID');
		ckRemove('ocms_user');
		ckRemove('ocms_token');
		lsRemove('ocms_userModules');
	}
};

export const checkBuildUpdate = () => {
	// Check whether the production build is updated or not.
	if (NODE_ENV === 'production') {
		let prevBuildTime = lsGet('ocms_buildTime');
		if (!prevBuildTime) {
			// The browser hasn't loaded this GUI before.
			recordBuildTime();
		} else {
			// The production build is updated, should reload the page without cache.
			if (prevBuildTime !== BUILD_TIME) {
				recordBuildTime(true);
				// Ignore static files cache reload.
				reloadWithoutCache();
			} else {
				if (window.location.href.indexOf('?') !== -1) {
					// If the URL has query for avoiding cache, then should remove it, cause
					// after the page reloading done, it is no longer useful.
					window.location.href = restructureURLQuery('host');
				}
			}
		}
	} else {
		// If dev env has url query should clear it. If we had run a build server to test the
		// production build before, this case may happen, we should fix it cause that is not
		// what we want in dev env.
		if (window.location.href.indexOf('?') !== -1) {
			window.location.href = deleteURLQuery(window.location.href);
		}
	}
};