import {dashboardActionTypes} from '@actions/dashboardAction';

const dashboardReducer = (state, action) => {
    const {dashboardData, statisticsPeriod} = action;

    switch (action.type) {
        case dashboardActionTypes.SET_DASHBOARD_DATA:
            const {overview, statistics} = dashboardData;
            return Object.assign({}, state, {overview, statistics});

        case dashboardActionTypes.SET_STATISTICS_PERIOD:
            return Object.assign({}, state, {statisticsPeriod});

        default:
            return state;
    }
};

export default dashboardReducer;