import {productActionTypes} from '@actions/productAction';

const productReducer = (state, action) => {
    const {productList, productLatestList, documentList, feedbackListOfDocument, imageGalleryList, afterSalesCaseList, guaranteeList, certificateList} = action;

    switch (action.type) {
        case productActionTypes.SET_PRODUCT_LIST:
            return Object.assign({}, state, {productList});

        case productActionTypes.SET_PRODUCT_LATEST_LIST:
            return Object.assign({}, state, {productLatestList});

        case productActionTypes.SET_DOCUMENT_LIST:
            return Object.assign({}, state, {documentList});

        case productActionTypes.SET_FEEDBACK_LIST_OF_DOCUMENT:
            return Object.assign({}, state, {feedbackListOfDocument});

        case productActionTypes.SET_IMAGE_GALLERY_LIST:
            return Object.assign({}, state, {imageGalleryList});

        case productActionTypes.SET_AFTER_SALES_CASE_LIST:
            return Object.assign({}, state, {afterSalesCaseList});

        case productActionTypes.SET_GUARANTEE_LIST:
            return Object.assign({}, state, {guaranteeList});

        case productActionTypes.SET_CERTIFICATE_LIST:
            return Object.assign({}, state, {certificateList});

        default:
            return state;
    }
};

export default productReducer;