import {businessActionTypes} from '@actions/businessAction';

const businessReducer = (state, action) => {
    const {consultationList, trialApplicationList} = action;

    switch (action.type) {
        case businessActionTypes.SET_CONSULTATION_LIST:
            return Object.assign({}, state, {consultationList});

        case businessActionTypes.SET_TRIAL_APPLICATION_LIST:
            return Object.assign({}, state, {trialApplicationList});

        default:
            return state;
    }
};

export default businessReducer;