import React, {lazy, Suspense, useEffect, useRef, useMemo, useCallback} from 'react';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';
import {useNetwork, useMappedState} from '@hooks';
import {OCMSPlaceholder} from '@components';
import {ckGet, lsGet, equals, getFirstAuthRoute} from '@utils';
import routes from '@routes';

// Sign In.
const SignIn = lazy(() => import('../SignIn/SignIn'));

// Main.
const Main = lazy(() => import('../Main/Main'));

// Dashboard.
const Dashboard = lazy(() => import('@views/Dashboard/Dashboard'));

// News.
const FirmNews = lazy(() => import('@views/FirmNews/FirmNews'));

// User.
const CMSUser = lazy(() => import('@views/CMSUser/CMSUser'));
const SiteUser = lazy(() => import('@views/SiteUser/SiteUser'));

// Business.
const Consultation = lazy(() => import('@views/Consultation/Consultation'));
const TrialApplication = lazy(() => import('@views/TrialApplication/TrialApplication'));

// Product.
const Product = lazy(() => import('@views/Product/Product'));
const Document = lazy(() => import('@views/Document/Document'));
const AfterSalesCase = lazy(() => import('@views/AfterSalesCase/AfterSalesCase'));
const ImageGallery = lazy(() => import('@views/ImageGallery/ImageGallery'));
const Guarantee = lazy(() => import('@views/Guarantee/Guarantee'));

// Storage quotation.
const Spq = lazy(() => import('@views/Spq/Spq'));

// Storage quotation source information input.
const SpqProduct = lazy(() => import('@views/SpqProduct/SpqProduct'));
const SpqCpu = lazy(() => import('@views/SpqCpu/SpqCpu'));
const SpqMemory = lazy(() => import('@views/SpqMemory/SpqMemory'));
const SpqDisk = lazy(() => import('@views/SpqDisk/SpqDisk'));
const SpqNetworkCard = lazy(() => import('@views/SpqNetworkCard/SpqNetworkCard'));
const SpqFunction = lazy(() => import('@views/SpqFunction/SpqFunction'));
const SpqConsumable = lazy(() => import('@views/SpqConsumable/SpqConsumable'));
const SpqPricingFormula = lazy(() => import('@views/SpqPricingFormula/SpqPricingFormula'));

// Storage quotation discount apply approval.
const SpqDiscountApproval = lazy(() => import('@views/SpqDiscountApproval/SpqDiscountApproval'));

// Certificate.
const Certificate = lazy(() => import('@views/Certificate/Certificate'));

// Recruitment.
const Job = lazy(() => import('@views/Job/Job'));
const JobApplication = lazy(() => import('@views/JobApplication/JobApplication'));

// Log.
const OperationLog = lazy(() => import('@views/OperationLog/OperationLog'));

function App (){
	// Redux related.
    const mapState = useCallback(state => {
        const {general: {user,}} = state;
        return {user};
    }, []);

	const {user} = useMappedState(mapState, equals);

	// Router path access auth control.
	let defaultPath = '';
	let isSignedIn = ckGet('ocms_token');
	if (!isSignedIn) {
		defaultPath = routes.SignIn;
	} else {
		defaultPath = routes.Main + getFirstAuthRoute(user.modules);
	}

	// Reference equivalent child components.
	const OCMSPlaceholderRef = useRef(<OCMSPlaceholder />);

	// Memorized data.
	const authorizedRouteArrMemo = useMemo(() => {
		const arr = [];
		let userModules = lsGet('ocms_userModules');
		if (user.id) {
			if (!userModules) {
				userModules = user.modules;
			}
		}

		if (userModules) {
			// User info is loaded.
			if (userModules.includes('Dashboard')) {
				arr.push(<Route key={routes.Dashboard} path={`${routes.Main}${routes.Dashboard}`} element={<Dashboard />} />);
			}
			if (userModules.includes('User')) {
				arr.push(<Route key={routes.CMSUser} path={`${routes.Main}${routes.CMSUser}`} element={<CMSUser />} />);
			}
			if (userModules.includes('Customer')) {
				arr.push(<Route key={routes.SiteUser} path={`${routes.Main}${routes.SiteUser}`} element={<SiteUser />} />);
			}
			if (userModules.includes('News')) {
				arr.push(<Route key={routes.FirmNews} path={`${routes.Main}${routes.FirmNews}`} element={<FirmNews />} />);
			}
			if (userModules.includes('Business')) {
				arr.push(
					<Route key={routes.Consultation} path={`${routes.Main}${routes.Consultation}`} element={<Consultation />} />,
					<Route key={routes.TrialApplication} path={`${routes.Main}${routes.TrialApplication}`} element={<TrialApplication />} />,
				);
			}
			if (userModules.includes('Product')) {
				arr.push(
					<Route key={routes.Product} path={`${routes.Main}${routes.Product}`} element={<Product />} />,
					<Route key={routes.Document} path={`${routes.Main}${routes.Document}`} element={<Document />} />,
					<Route key={routes.AfterSalesCase} path={`${routes.Main}${routes.AfterSalesCase}`} element={<AfterSalesCase />} />,
					<Route key={routes.ImageGallery} path={`${routes.Main}${routes.ImageGallery}`} element={<ImageGallery />} />,
					<Route key={routes.Guarantee} path={`${routes.Main}${routes.Guarantee}`} element={<Guarantee />} />,
				);
			}
			if (userModules.includes('StorageQuote')) {
				arr.push(<Route key={routes.Spq} path={`${routes.Main}${routes.Spq}`} element={<Spq />} />);
			}
			if (userModules.includes('StorageQuoteSource')) {
				arr.push(
					<Route key={routes.SpqProduct} path={`${routes.Main}${routes.SpqProduct}`} element={<SpqProduct />} />,
					<Route key={routes.SpqCpu} path={`${routes.Main}${routes.SpqCpu}`} element={<SpqCpu />} />,
					<Route key={routes.SpqMemory} path={`${routes.Main}${routes.SpqMemory}`} element={<SpqMemory />} />,
					<Route key={routes.SpqDisk} path={`${routes.Main}${routes.SpqDisk}`} element={<SpqDisk />} />,
					<Route key={routes.SpqNetworkCard} path={`${routes.Main}${routes.SpqNetworkCard}`} element={<SpqNetworkCard />} />,
					<Route key={routes.SpqFunction} path={`${routes.Main}${routes.SpqFunction}`} element={<SpqFunction />} />,
					<Route key={routes.SpqConsumable} path={`${routes.Main}${routes.SpqConsumable}`} element={<SpqConsumable />} />,
					<Route key={routes.SpqPricingFormula} path={`${routes.Main}${routes.SpqPricingFormula}`} element={<SpqPricingFormula />} />,
				);
			}
			if (userModules.includes('StorageQuoteDiscountApproval')) {
				arr.push(<Route key={routes.SpqDiscountApproval} path={`${routes.Main}${routes.SpqDiscountApproval}`} element={<SpqDiscountApproval />} />);
			}
			if (userModules.includes('Certificate')) {
				arr.push(<Route key={routes.Certificate} path={`${routes.Main}${routes.Certificate}`} element={<Certificate />} />);
			}
			if (userModules.includes('Recruitment')) {
				arr.push(
					<Route key={routes.Job} path={`${routes.Main}${routes.Job}`} element={<Job />} />,
					<Route key={routes.JobApplication} path={`${routes.Main}${routes.JobApplication}`} element={<JobApplication />} />,
				);
			}
			if (userModules.includes('Log')) {
				arr.push(<Route key={routes.OperationLog} path={`${routes.Main}${routes.OperationLog}`} element={<OperationLog />} />);
			}

			// Redirect no matched route to default path depends on its auth.
			arr.push(<Route key="not-found" path="*" element={<Navigate to={arr[0].props.path} replacement />} />);
		}

		return arr;
	}, [user]);

	const RouteNestedOutletSlotMemo = useMemo(() => authorizedRouteArrMemo.length === 0 ? null : authorizedRouteArrMemo, [authorizedRouteArrMemo]);

	// After rendering and re-rendering.
	useNetwork();

	useEffect(() => {
		// Intercept the unauthorized route access from SignIn to Main.
		// *** This is just a temp hack.
		// Because react-router-dom v6 uses a different route match algorithm, this causes
		// Main component's route '/cms' can not be individually matched, so the auth interceptor
		// in Main can not take effect. For v5 there is no problem for Main and does not need this.
		// The v6's match algorithm and usage need to be checked in depth.
		const hashChange = (e) => {
			if (e.oldURL.includes(routes.SignIn) && e.newURL.includes(routes.Main)) {
				const isSignedIn = ckGet('ocms_token');
				if (!isSignedIn) {
					window.location.hash = routes.SignIn;
				}
			}
		};

		window.addEventListener('hashchange', hashChange);

		return () => {
			window.removeEventListener('hashchange', haushChange);
		};
	}, []);

	return (
		<HashRouter>
			<Suspense fallback={OCMSPlaceholderRef.current}>
				<Routes>
					<Route path={routes.SignIn} element={<SignIn />} />
					<Route path={routes.Main} element={<Main />}>
						{RouteNestedOutletSlotMemo}
					</Route>

					<Route path={routes.Root} element={<Navigate to={defaultPath} replace />} />
				</Routes>
			</Suspense>
		</HashRouter>
	);
}

export default App;