export const generalActionTypes = {
    SET_USER: 'GENERAL@SET_USER',
    SET_ACTIVE_MENU: 'GENERAL@SET_ACTIVE_MENU',
    SET_ACTIVE_PAGE: 'GENERAL@SET_ACTIVE_PAGE',
};

export default {
    setUser: user => ({
        type: generalActionTypes.SET_USER,
        user
    }),

    setActiveMenu: activeMenu => ({
        type: generalActionTypes.SET_ACTIVE_MENU,
        activeMenu
    }),

    setActivePage: activePage => ({
        type: generalActionTypes.SET_ACTIVE_PAGE,
        activePage
    }),
};