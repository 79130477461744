import {generalActionTypes} from '@actions/generalAction';

const generalReducer = (state, action) => {
    const {user, activeMenu, activePage} = action;

    switch (action.type) {
        case generalActionTypes.SET_USER:
            return Object.assign({}, state, {user});

        case generalActionTypes.SET_ACTIVE_MENU:
            return Object.assign({}, state, {activeMenu});

        case generalActionTypes.SET_ACTIVE_PAGE:
            return Object.assign({}, state, {activePage});

        default:
            return state;
    }
};

export default generalReducer;