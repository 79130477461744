import {combineReducers} from 'redux';
import State from '../state';
import generalReducer from './generalReducer';
import dashboardReducer from './dashboardReducer';
import userReducer from './userReducer';
import newsReducer from './newsReducer';
import businessReducer from './businessReducer';
import productReducer from './productReducer';
import storageQuoteReducer from './storageQuoteReducer';
import recruitmentReducer from './recruitmentReducer';
import logReducer from './logReducer';

// Export as a combined reducer
export default combineReducers({
	general: (modal = State.general, action) => generalReducer(modal, action),

	dashboard: (modal = State.dashboard, action) => dashboardReducer(modal, action),

    user: (user = State.user, action) => userReducer(user, action),

	news: (news = State.news, action) => newsReducer(news, action),

    business: (user = State.business, action) => businessReducer(user, action),

	product: (recruitment = State.product, action) => productReducer(recruitment, action),

	storageQuote: (storageQuote = State.storageQuote, action) => storageQuoteReducer(storageQuote, action),

	recruitment: (recruitment = State.recruitment, action) => recruitmentReducer(recruitment, action),

	log: (log = State.log, action) => logReducer(log, action),
});