import {routeToMenu} from '@routes';

export const getFirstAuthRoute = (modules) => {
	let firstRoute = '';
	const menuKeys = Object.keys(routeToMenu);
	for (let module of modules) {
		for (let menuKey of menuKeys) {
			if (module === menuKey) {
				firstRoute = routeToMenu[menuKey][0];
				break;
			}
		}
		if (firstRoute !== '') {
			break;
		}
	}
	return firstRoute;
};