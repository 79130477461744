export const deleteURLQuery = (url) => {
	const queryIndex = url.indexOf('?');
	const hashIndex = url.indexOf('#');
	return url.substring(0, queryIndex) + url.substring(hashIndex);
};

export const restructureURLQuery = (needWhat) => {
	const {protocol, host, hash} = window.location;
	const URLWithHost = `${protocol}//${host}`;
	if (needWhat === 'host') {
		return URLWithHost;
	} else if (needWhat === 'hash') {
		return `${URLWithHost}/${hash}`;
	} else {
		return `${URLWithHost}/${hash}`;
	}
};

const addURLQuery = (url) => url + '?' + Date.now();

export const reloadWithoutCache = () => {
	// For current browser security policy, the two ancient methods that are used to reload page without
	// cache are not working any more：
	// window.location.reload(true);
	// window.location.href = window.location.href.replace(/#.*$/, '');
	//
	// So from then on, we can only add no-cache <meta> in HTML <head> or add a query into URL to force
	// browsers reload the HTML instead of going through the cache strategy. Here we take the second way.
	let href = restructureURLQuery('host');
	href = addURLQuery(href);
	window.location.href = href;
};