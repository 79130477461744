import store from '@store';

// Consider to the point of 'this' in decorator, we must be cautious
// of the  using of 'function' and 'arrow function'.

// To decorate a class, then:
// 'target' refers to the class,
// 'key' refers to undefined,
// 'descriptor' refers to undefined.

// Another usage: to decorate a member method of a class, thins will be like this:
// 'target' refers to the class extends from React.Component,
// 'key' refers to the member property name,
// 'descriptor' refers to the member property method.

// ES7 decorator is implemented based on Object.defineProperty(obj, prop, descriptor) .

// Fetch error decorator.
// This decorator is used on the APIs that are aimed at getting data only,
// especially getting data in background.
// The APIs which are used by CRUD operations will catch error by the operations
// themselves in specific business codes.
export function catchAPIError (target, key, descriptor){
	return Object.assign({}, descriptor, {
		value: async function (...arg){
			try {
				return await descriptor.value.apply(this, arg);
			} catch (e) {
				console.info('HTTP Error: ' + (e.msg || e || 'No clear error information.'));
				if (process.env.NODE_ENV === 'test') {
					console.info(descriptor.value);
				}
			}
		}
	});
}

// Dispatch redux action decorator.
export function dispatchAction (actionModule, action/*, dataKeys*/){
	return function (target, key, descriptor){
		return Object.assign({}, descriptor, {
			value: async function (...arg){
				let data = await descriptor.value.apply(this, arg);
				if (data) {
					store.dispatch(actionModule[action](data));
					/*
					let rawData = Object.assign({}, data);
					// actionModule, action, dataKey
					let dispatchData = {};
					if (Array.isArray(dataKeys)){
						dataKeys.forEach(dataKey => dispatchData[dataKey] = data[dataKey]);
					} else {
						dispatchData = data;
					}
					store.dispatch(actionModule[action](dispatchData));
					return rawData;
					*/
				} else if (data === 0) {
					store.dispatch(actionModule[action](data));
				}
				return data;
			}
		});
	};
}