import {useEffect} from 'react';

export default function useRenderChart (option, isDataReadyMemo, generateChartOption, chartInstanceRef, initialRendered, idleCallbackID) {
	// If option is changed, render or re-render the chart.
	useEffect(() => {
		const chartOption = generateChartOption(option);

		if (option.renderMode === 'async') {
			// Asynchronous render mode.
			if (!initialRendered.current) {
				// Force the initial render be executed asynchronously when main thread is idle
				// for the first screen performance.
				if (!idleCallbackID.current) {
					// If there are more than one re-renderings(function calls) during the initial
					// presentation phase of this component, should only take the first one of them.
					// Cases that can cause more than one rendering during the initial presentation
					// phase: meaningless rendering triggered by parent component or current component
					// from confusion logic codes or bug. This lock will help preventing rendering
					// from being affected by them.
					if (isDataReadyMemo) {
						// Also should filter out the empty redux state case when this component
						// is initial rendering. Waiting for RESTful API data is ready.
						idleCallbackID.current = requestIdleCallback(() => {
							if (chartInstanceRef.current) {
								chartInstanceRef.current.setOption(chartOption);
							}
							// Release locks.
							initialRendered.current = true;
							idleCallbackID.current = null;
						}, {
							// Waiting for 1.5 seconds at most. After expiration, enforcement rendering.
							timeout: 1500
						});
					}
				}
			} else {
				// Only after async initial rendering is done, execution in main thread
				// can work. Also all of the subsequent rendering after the initial one
				// should be executed in main thread directly.
				if (process.env.NODE_ENV !== 'test') {
					// If it is in test env, should drop this operation because of canvas is
					// neither supported nor emulated in current test env.
					chartInstanceRef.current.setOption(chartOption);
				}
			}
		} else {
			// Synchronous render mode.
			// Both initial rendering and subsequent re-rendering should be executed
			// in main thread directly.
			chartInstanceRef.current.setOption(chartOption);
		}
	}, [option, isDataReadyMemo, generateChartOption, chartInstanceRef, initialRendered, idleCallbackID]);

	// When component destroyed, but the chart is not initialized, should cancel the timer.
	useEffect(() => {
		return () => {
			if (initialRendered.current) {
				window.cancelIdleCallback(initialRendered.current);
			}
		};
	}, [initialRendered]);
}