import {userActionTypes} from '@actions/userAction';

const userReducer = (state, action) => {
	const {CMSUserList, siteUserList} = action;

	switch (action.type) {
		case userActionTypes.SET_CMS_USER_LIST:
			return Object.assign({}, state, {CMSUserList});

		case userActionTypes.SET_SITE_USER_LIST:
			return Object.assign({}, state, {siteUserList});

		default:
			return state;
	}
};

export default userReducer;