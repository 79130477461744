export const dashboardActionTypes = {
    SET_DASHBOARD_DATA: 'DASHBOARD@SET_DASHBOARD_DATA',
    SET_STATISTICS_PERIOD: 'DASHBOARD@SET_STATISTICS_PERIOD',
};

export default {
    setDashboardData: dashboardData => ({
        type: dashboardActionTypes.SET_DASHBOARD_DATA,
        dashboardData
    }),

    setStatisticsPeriod: statisticsPeriod => ({
        type: dashboardActionTypes.SET_STATISTICS_PERIOD,
        statisticsPeriod
    }),
};