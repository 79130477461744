import React, {useCallback, useEffect} from 'react';
import {useMappedState} from '@hooks';
import {Checkbox, Form, Icon, Popover} from 'antd';
import {equals} from '@utils';
import httpCaller from '@http/caller';

export default function OCMSLinkProductModel ({sourceType: pSourceType, value: pValue, onChange: pOnChange}){
	// Redux related.
	const mapState = useCallback(state => {
		const {storageQuote: {source: {productList}}} = state;
		return {productList};
	}, []);

	const {productList} = useMappedState(mapState, equals);

	// After rendering and re-rendering.
	useEffect(() => {
		httpCaller.getSpqProductList();
	}, []);

	return (
		<Form.Item
			label={
				<>
					关联产品型号
					<Popover
						placement="right"
						content={
							pSourceType !== '报价公式' ? (
								<div style={{width: 400}}>
									支持多选。勾选某个产品型号以后，表示在产品报价表单里，在选择此产品型号后，该{pSourceType}将展示在{pSourceType}这栏以供选择。<br />
									支持全选和全部不选。如果全选或者全部不选，表示在产品报价单里所有产品型号均支持选择此{pSourceType}。
								</div>
							) : (
								<div style={{width: 400}}>
									支持多选。勾选某个产品型号后，表示此产品型号将应用该报价公式进行报价计算。在创建报价单时，系统会自动根据选择的产品型号所应用的报价公式进行报价计算。<br />
									请注意：一个产品型号只能应用一个报价公式。在以为某个产品型号应用一个报价公式后，再为该产品型号应用另外一个报价公式，前一个关联将自动取消掉，将以最后一个为准。
								</div>
							)
						}
					>
						<Icon type="question-circle-o" className="ocms-info-icon m-l" />
					</Popover>
				</>
			}
		>
			<Checkbox.Group
				value={pValue}
				onChange={pOnChange}
			>
				{
					productList.map(({name, models}) => {
						const options = models.reduce((prev, model) => {
							prev.push({
								label: model.name,
								value: `${model.name}@${model.product}`,
							});
							return prev;
						}, []);

						return (
							<div className="ocms-link-product-model-item" key={name}>
								<div className="ocms-link-product-model-title">{name}</div>
								{
									options.map(option => (
										<Checkbox
											key={option.value}
											value={option.value}
										>
											{option.label}
										</Checkbox>
									))
								}
							</div>
						);
					})
				}
			</Checkbox.Group>
		</Form.Item>
	);
}