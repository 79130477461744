import {logActionTypes} from '@actions/logAction';

const logReducer = (state, action) => {
	const {operationLogList} = action;

	switch (action.type) {
		case logActionTypes.SET_OPERATION_LOG_LIST:
			return Object.assign({}, state, {operationLogList});

		default:
			return state;
	}
};

export default logReducer;