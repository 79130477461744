import {recruitmentActionTypes} from '@actions/recruitmentAction';

const recruitmentReducer = (state, action) => {
    const {jobList, jobApplicationList} = action;
    
    switch (action.type) {
        case recruitmentActionTypes.SET_JOB_LIST:
            return Object.assign({}, state, {jobList});

        case recruitmentActionTypes.SET_JOB_APPLICATION_LIST:
            return Object.assign({}, state, {jobApplicationList});

        default:
            return state;
    }
};

export default recruitmentReducer;