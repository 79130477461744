import {stringify} from 'querystring';
import {ckGet, lsRemove} from '@utils';
import routes from '@routes';

const initRequest = async (url, options, isFormData) => {
	return new Promise(async (resolve, reject) => {
		makeFetchOptions(options, isFormData);
		try {
			const response = await fetch(addURLVersionPrefix(url), options);
			if (response.ok){
				let {code, data, msg} = await response.json();
				if (code === 0) {
					resolve(data);
				} else {
					reject({code, msg});
				}
			} else {
				const {status, statusText} = response;
				reject({msg: statusText || `http error, code: ${status}`});
				console.info(`%c http error, code: ${status}`, 'color: #CC0033');
			}
			checkSignInStatus();
		} catch (e){
			reject({msg: handleBrowserNetworkError(e.msg ? e.msg : e)});
		}
	});
};

const initBlobRequest = async (url, options, isFormData) => {
	return new Promise(async (resolve, reject) => {
		makeFetchOptions(options, isFormData);
		try {
			const response = await fetch(addURLVersionPrefix(url), options);
			if (response.ok){
				resolve(await response.blob());
			}
		} catch (e){
			reject({msg: handleBrowserNetworkError(e.msg ? e.msg : e)});
		}
	});
};

const handleParam = (param) => {
    if (param) {
        if (typeof param === 'string') {
            // Example: '/snapshot/snapshot_1', method: GET(single, list un-paginated, etc)|POST|DELETE.
            return '/' + param;
        } else {
            // Example: '/snapshot?page=1&limit=10', method: GET(list paginated, etc).
            return '?' + stringify(param);
        }
    } else {
        return '';
    }
};

export const initSearchUrl = (url, param) => url + handleParam(param);

const checkSignInStatus = () => {
	if (!window.location.hash.includes(routes.SignIn)) {
		let isSignedIn = ckGet('ocms_token');
		if (!isSignedIn) {
			lsRemove('ocms_userModules');
			window.location.hash = routes.SignIn;
		}
	}
};

const handleBrowserNetworkError = (e) => {
	// If fetch error occur due to some network error of the browser
	// which are not caused by our API, should give out a clear prompt.
	if (!e) return e;
	e = e.msg || e;
	try {
		if (/Failed to fetch/i.test(e)) {
			// This is an network error thrown by browser.
			return '请求失败，请检查您的网络！';
		} else {
			return e;
		}
	} catch(exception){
		return e;
	}
};

const API_VERSION = '/api/v2';

const addURLVersionPrefix = (url) => `${API_VERSION}${url}`;

const makeFetchOptions = (options, isFormData) => {
	let headers = {
		'Content-Type': 'application/json; charset=utf-8',
	};
	const token = ckGet('ocms_token');
	if (token) {
		headers['Authorization'] = `Bearer ${token}`;
	}
	Object.assign(options, {
		credentials: 'same-origin',
		withCredentials: true,
		headers,
	});
	if (isFormData){
		delete options.headers;
	}
};

export const fetchGet = (url, param) => initRequest(initSearchUrl(url, param), {method: 'GET'});

export const fetchPost = (url, param, isFormData) => initRequest(url, {method: 'POST', body: !isFormData ? JSON.stringify(param) : param}, isFormData);

export const fetchPut = (url, param) => initRequest(url, {method: 'PUT', body: JSON.stringify(param)});

export const fetchDelete = (url, param) => initRequest(initSearchUrl(url, param), {method: 'DELETE'});

export const fetchBlobGet = (url, param) => initBlobRequest(initSearchUrl(url, param), {method: 'GET'});