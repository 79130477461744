// Polyfill will be transpiled target the specific browsers.
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import {render} from 'react-dom';
import {StoreContext} from 'redux-react-hook';
import store from '@store';
import '@styles';
import App from '@views/App/App';
import {checkBuildUpdate, ckGet, printProjectInfo} from '@utils';
import httpCaller from '@http/caller';
import cronJob from  '@http/cronJob';

// Check that whether build is update, if it is, remove all sign in cookies,
// cause use old cookies to call 'syncUpClusterStatus' from CP, CP side will hang,
// and the page will stay in white.
checkBuildUpdate();

(async () => {
	const isSignedIn = ckGet('ocms_token');

	if (isSignedIn) {
		await httpCaller.getSignedInCMSUser();
	}

	// Mount GUI Application on this DOM.
	let rootContainer = document.getElementById('ocms-app-root');

	let AppWithStore = (
		<StoreContext.Provider value={store}>
			<App/>
		</StoreContext.Provider>
	);

	// Render App.
	render(AppWithStore, rootContainer);

	// Initialize cronJob and run playbook.
	cronJob.runPlaybook();

	// Print project info.
	printProjectInfo();
})();