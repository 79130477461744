export const userActionTypes = {
	SET_CMS_USER_LIST: 'USER@SET_CMS_USER_LIST',
	SET_SITE_USER_LIST: 'USER@SET_SITE_USER_LIST',
};

export default {
	setCMSUserList: CMSUserList => ({
		type: userActionTypes.SET_CMS_USER_LIST,
		CMSUserList
	}),

	setSiteUserList: siteUserList => ({
		type: userActionTypes.SET_SITE_USER_LIST,
		siteUserList
	}),
};