import {fetchDelete, fetchGet, fetchPost, fetchPut, fetchBlobGet} from '@http/fetch';
import generalAction from '@actions/generalAction';
import dashboardAction from '@actions/dashboardAction';
import newsAction from '@actions/newsAction';
import userAction from '@actions/userAction';
import businessAction from '@actions/businessAction';
import productAction from '@actions/productAction';
import storageQuoteAction from '@actions/storageQuoteAction';
import logAction from '@actions/logAction';
import recruitmentAction from '@actions/recruitmentAction';
import {catchAPIError, ckGet, dispatchAction, lsGet} from '@utils';

class HttpCaller {
	// Sign In.
	async signIn (user){
		return await fetchPost('/sessions', user);
	}

	// Sign Out.
	async signOut (id = ckGet('ocms_sessionID')){
		return await fetchDelete(`/sessions/${id}`);
	}

	// Get signed in user information.
	@dispatchAction(generalAction, 'setUser')
	@catchAPIError
	async getSignedInCMSUser (username = ckGet('ocms_user')){
		return await fetchGet(`/users/query?username=${username}`);
	}

	// Change signed in user's password.
	async changePassword (CMSUser){
		return await fetchPost('/users/change-password', CMSUser);
	}

	// Dashboard.
	@dispatchAction(dashboardAction, 'setDashboardData')
	@catchAPIError
	async getDashboardData (query = {stage: 'week'}){
		return await fetchGet('/api/overview', query);
	}

	// CMS user.
	@dispatchAction(userAction, 'setCMSUserList')
	@catchAPIError
	async getCMSUserList (){
		return await fetchGet('/users');
	}

	@dispatchAction(userAction, 'setCMSUserList')
	async createCMSUser (CMSUser){
		return await fetchPost('/users', CMSUser);
	}

	@dispatchAction(userAction, 'setCMSUserList')
	async editCMSUser (CMSUser){
		return await fetchPut(`/users/${CMSUser.id}`, CMSUser);
	}

	@dispatchAction(userAction, 'setCMSUserList')
	async resetCMSUserPassword (CMSUser){
		return await fetchPut(`/users/${CMSUser.id}/reset-password`, CMSUser);
	}

	@dispatchAction(userAction, 'setCMSUserList')
	async deleteCMSUser (siteUser){
		return await fetchDelete(`/users/${siteUser.id}`);
	}

	// Official site customer user.
	@dispatchAction(userAction, 'setSiteUserList')
	@catchAPIError
	async getSiteUserList (){
		return await fetchGet('/customers');
	}

	@dispatchAction(userAction, 'setSiteUserList')
	async createSiteUser (siteUser){
		return await fetchPost('/customers', siteUser);
	}

	@dispatchAction(userAction, 'setSiteUserList')
	async editSiteUser (siteUser){
		return await fetchPut(`/customers/${siteUser.id}`, siteUser);
	}

	@dispatchAction(userAction, 'setSiteUserList')
	async resetSiteUserPassword (siteUser){
		return await fetchPut(`/customers/${siteUser.id}/reset-password`, siteUser);
	}

	@dispatchAction(userAction, 'setSiteUserList')
	async deleteSiteUser (siteUser){
		return await fetchDelete(`/customers/${siteUser.id}`);
	}

	// News.
	// Firm News.
	@dispatchAction(newsAction, 'setFirmNewsList')
	@catchAPIError
	async getFirmNewsList (){
		return await fetchGet('/news');
	}

	async getFirmNewsByID (id){
		return await fetchGet(`/news/${id}`);
	}

	@dispatchAction(newsAction, 'setFirmNewsList')
	async createFirmNews (firmNews){
		return await fetchPost('/news', firmNews);
	}

	@dispatchAction(newsAction, 'setFirmNewsList')
	async editFirmNews (firmNews){
		return await fetchPut(`/news/${firmNews.id}`, firmNews);
	}

	@dispatchAction(newsAction, 'setFirmNewsList')
	async deleteFirmNews (firmNews){
		return await fetchDelete(`/news/${firmNews.id}`);
	}

	// Business.
	// Consultation.
	@dispatchAction(businessAction, 'setConsultationList')
	@catchAPIError
	async getConsultationList (){
		return await fetchGet('/comments');
	}

	@dispatchAction(businessAction, 'setConsultationList')
	async handleConsultation (consultation){
		return await fetchPut(`/comments/${consultation.id}`, consultation);
	}

	@dispatchAction(businessAction, 'setConsultationList')
	async deleteConsultation (consultation){
		return await fetchDelete(`/comments/${consultation.id}`);
	}

	// Trial Application.
	@dispatchAction(businessAction, 'setTrialApplicationList')
	@catchAPIError
	async getTrialApplicationList (){
		return await fetchGet('/trials');
	}

	@dispatchAction(businessAction, 'setTrialApplicationList')
	async handleTrialApplication (trialApplication){
		return await fetchPut(`/trials/${trialApplication.id}`, trialApplication);
	}

	@dispatchAction(businessAction, 'setTrialApplicationList')
	async deleteTrialApplication (trialApplication){
		return await fetchDelete(`/trials/${trialApplication.id}`);
	}

	// Product And Document.
	// Product.
	@dispatchAction(productAction, 'setProductList')
	@catchAPIError
	async getProductList (){
		return await fetchGet('/products');
	}

	@dispatchAction(productAction, 'setProductLatestList')
	@catchAPIError
	async getProductLatestList (){
		let data = await fetchGet('/products');
		// Make the latest data to be on the front.
		data = data.slice(0).reverse().map(product => {
			product.versions = product.versions.slice(0).reverse();
			return product;
		});
		return data;
	}

	@dispatchAction(productAction, 'setProductList')
	async createProduct (product){
		return await fetchPost('/products', product);
	}

	@dispatchAction(productAction, 'setProductList')
	async editProduct (product){
		return await fetchPut(`/products/${product.id}`, product);
	}

	@dispatchAction(productAction, 'setProductList')
	async deleteProduct (product){
		return await fetchDelete(`/products/${product.id}`);
	}

	// Document.
	@dispatchAction(productAction, 'setDocumentList')
	@catchAPIError
	async getDocumentList (query = (lsGet('ocms_documentQuery') || {})){
		return await fetchGet('/documents', query);
	}

	async createDocument (document){
		return await fetchPost('/documents', document);
	}

	async editDocument (document){
		return await fetchPut(`/documents/${document.id}`, document);
	}

	async deleteDocument (document){
		return await fetchDelete(`/documents/${document.id}`);
	}

	@dispatchAction(productAction, 'setFeedbackListOfDocument')
	@catchAPIError
	async getFeedbackListByDocumentId (document){
		return await fetchGet(`/feedbacks?documentID=${document.id}`);
	}

	@dispatchAction(productAction, 'setFeedbackListOfDocument')
	async deleteFeedBack (feedback){
		return await fetchDelete(`/feedbacks/${feedback.id}`);
	}

	// After sales case.
	@dispatchAction(productAction, 'setAfterSalesCaseList')
	@catchAPIError
	async getAfterSalesCaseList (query = (lsGet('ocms_afterSalesCaseQuery') || {})){
		return await fetchGet('/after-sales-cases', query);
	}

	async createAfterSalesCase (afterSalesCase){
		return await fetchPost('/after-sales-cases', afterSalesCase);
	}

	async editAfterSalesCase (afterSalesCase){
		return await fetchPut(`/after-sales-cases/${afterSalesCase.id}`, afterSalesCase);
	}

	async deleteAfterSalesCase (afterSalesCase){
		return await fetchDelete(`/after-sales-cases/${afterSalesCase.id}`);
	}

	// Image Gallery.
	@dispatchAction(productAction, 'setImageGalleryList')
	@catchAPIError
	async getImageGalleryList (query = (lsGet('ocms_imageGalleryQuery') || {})){
		return await fetchGet('/image-galleries', query);
	}

	async createImageGallery (imageGallery){
		return await fetchPost('/image-galleries', imageGallery);
	}

	async editImageGallery (imageGallery){
		return await fetchPut(`/image-galleries/${imageGallery.id}`, imageGallery);
	}

	async showImageGallery (imageGallery){
		return await fetchPut(`/image-galleries/${imageGallery.id}`, imageGallery);
	}

	async deleteImageGallery (imageGallery){
		return await fetchDelete(`/image-galleries/${imageGallery.id}`);
	}

	// Guarantee.
	@dispatchAction(productAction, 'setGuaranteeList')
	@catchAPIError
	async getGuaranteeList (query){
		return await fetchGet('/guarantees', query);
	}

	@dispatchAction(productAction, 'setGuaranteeList')
	async createGuarantee (guarantee){
		return await fetchPost('/guarantees', guarantee);
	}

	@dispatchAction(productAction, 'setGuaranteeList')
	async editGuarantee (guarantee){
		return await fetchPut(`/guarantees/${guarantee.id}`, guarantee);
	}

	@dispatchAction(productAction, 'setGuaranteeList')
	async deleteGuarantee (guarantee){
		return await fetchDelete(`/guarantees/${guarantee.id}`);
	}

	// Storage product quote.
	// Quote.
	@dispatchAction(storageQuoteAction, 'setQuoteList')
	@catchAPIError
	async getSpqList (){
		return await fetchGet('/storage-quotes');
	}

	@dispatchAction(storageQuoteAction, 'setQuoteList')
	async createSpq (quote){
		return await fetchPost('/storage-quotes', quote);
	}

	@dispatchAction(storageQuoteAction, 'setQuoteList')
	async editSpq (quote){
		return await fetchPut(`/storage-quotes/${quote.id}`, quote);
	}

	async downloadSpqExcel (quote){
		return await fetchBlobGet(`/storage-quotes/${quote.id}/download-excel`);
	}

	async downloadSpqPdf (quote){
		return await fetchBlobGet(`/storage-quotes/${quote.id}/download-pdf`);
	}

	async applySpqDiscount (discount){
		return await fetchPost('/storage-quote-discount-approvals/submit', discount);
	}

	async revokeSpqDiscountApply (apply){
		return await fetchPut(`/storage-quote-discount-approvals/${apply.id}/revoke`);
	}

	async deleteSpqDiscountApplyRecord (apply){
		return await fetchPut(`/storage-quote-discount-approvals/${apply.id}/ignore`);
	}

	@dispatchAction(storageQuoteAction, 'setQuoteList')
	async deleteSpq (quote){
		return await fetchDelete(`/storage-quotes/${quote.id}`);
	}

	// Storage product quote product.
	@dispatchAction(storageQuoteAction, 'setProductList')
	@catchAPIError
	async getSpqProductList (){
		return await fetchGet('/storage-quote-sources/products');
	}

	@dispatchAction(storageQuoteAction, 'setProductList')
	async createSpqProduct (product){
		return await fetchPost('/storage-quote-sources/products', product);
	}

	@dispatchAction(storageQuoteAction, 'setProductList')
	async editSpqProduct (product){
		return await fetchPut(`/storage-quote-sources/products/${product.id}`, product);
	}

	@dispatchAction(storageQuoteAction, 'setProductList')
	async deleteSpqProduct (product){
		return await fetchDelete(`/storage-quote-sources/products/${product.id}`);
	}

	// Storage product quote product model.
	@dispatchAction(storageQuoteAction, 'setProductModelList')
	@catchAPIError
	async getSpqProductModelList (){
		return await fetchGet('/storage-quote-sources/product-models');
	}

	@dispatchAction(storageQuoteAction, 'setProductModelList')
	async createSpqProductModel (productModel){
		return await fetchPost('/storage-quote-sources/product-models', productModel);
	}

	@dispatchAction(storageQuoteAction, 'setProductModelList')
	async editSpqProductModel (productModel){
		return await fetchPut(`/storage-quote-sources/product-models/${productModel.id}`, productModel);
	}

	@dispatchAction(storageQuoteAction, 'setProductModelList')
	async deleteSpqProductModel (productModel){
		return await fetchDelete(`/storage-quote-sources/product-models/${productModel.id}`);
	}

	// Storage product quote cpu vendor.
	@dispatchAction(storageQuoteAction, 'setCpuVendorList')
	@catchAPIError
	async getSpqCpuVendorList (){
		return await fetchGet('/storage-quote-sources/cpu-vendors');
	}

	@dispatchAction(storageQuoteAction, 'setCpuVendorList')
	async createSpqCpuVendor (cpuVendor){
		return await fetchPost('/storage-quote-sources/cpu-vendors', cpuVendor);
	}

	@dispatchAction(storageQuoteAction, 'setCpuVendorList')
	async editSpqCpuVendor (cpuVendor){
		return await fetchPut(`/storage-quote-sources/cpu-vendors/${cpuVendor.id}`, cpuVendor);
	}

	@dispatchAction(storageQuoteAction, 'setCpuVendorList')
	async deleteSpqCpuVendor (cpuVendor){
		return await fetchDelete(`/storage-quote-sources/cpu-vendors/${cpuVendor.id}`);
	}

	// Storage product quote cpu.
	@dispatchAction(storageQuoteAction, 'setCpuList')
	@catchAPIError
	async getSpqCpuList (){
		return await fetchGet('/storage-quote-sources/cpus');
	}

	@dispatchAction(storageQuoteAction, 'setCpuList')
	async createSpqCpu (cpu){
		return await fetchPost('/storage-quote-sources/cpus', cpu);
	}

	@dispatchAction(storageQuoteAction, 'setCpuList')
	async editSpqCpu (cpu){
		return await fetchPut(`/storage-quote-sources/cpus/${cpu.id}`, cpu);
	}

	@dispatchAction(storageQuoteAction, 'setCpuList')
	async deleteSpqCpu (cpu){
		return await fetchDelete(`/storage-quote-sources/cpus/${cpu.id}`);
	}

	// Storage product quote memory.
	@dispatchAction(storageQuoteAction, 'setMemoryList')
	@catchAPIError
	async getSpqMemoryList (){
		return await fetchGet('/storage-quote-sources/rams');
	}

	@dispatchAction(storageQuoteAction, 'setMemoryList')
	async createSpqMemory (memory){
		return await fetchPost('/storage-quote-sources/rams', memory);
	}

	@dispatchAction(storageQuoteAction, 'setMemoryList')
	async editSpqMemory (memory){
		return await fetchPut(`/storage-quote-sources/rams/${memory.id}`, memory);
	}

	@dispatchAction(storageQuoteAction, 'setMemoryList')
	async deleteSpqMemory (memory){
		return await fetchDelete(`/storage-quote-sources/rams/${memory.id}`);
	}

	// Storage product quote disk vendor.
	@dispatchAction(storageQuoteAction, 'setDiskVendorList')
	@catchAPIError
	async getSpqDiskVendorList (){
		return await fetchGet('/storage-quote-sources/disk-vendors');
	}

	@dispatchAction(storageQuoteAction, 'setDiskVendorList')
	async createSpqDiskVendor (diskVendor){
		return await fetchPost('/storage-quote-sources/disk-vendors', diskVendor);
	}

	@dispatchAction(storageQuoteAction, 'setDiskVendorList')
	async editSpqDiskVendor (diskVendor){
		return await fetchPut(`/storage-quote-sources/disk-vendors/${diskVendor.id}`, diskVendor);
	}

	@dispatchAction(storageQuoteAction, 'setDiskVendorList')
	async deleteSpqDiskVendor (diskVendor){
		return await fetchDelete(`/storage-quote-sources/disk-vendors/${diskVendor.id}`);
	}

	// Storage product quote disk.
	@dispatchAction(storageQuoteAction, 'setDiskList')
	@catchAPIError
	async getSpqDiskList (){
		return await fetchGet('/storage-quote-sources/disks');
	}

	@dispatchAction(storageQuoteAction, 'setDiskList')
	async createSpqDisk (disk){
		return await fetchPost('/storage-quote-sources/disks', disk);
	}

	@dispatchAction(storageQuoteAction, 'setDiskList')
	async editSpqDisk (disk){
		return await fetchPut(`/storage-quote-sources/disks/${disk.id}`, disk);
	}

	@dispatchAction(storageQuoteAction, 'setDiskList')
	async deleteSpqDisk (disk){
		return await fetchDelete(`/storage-quote-sources/disks/${disk.id}`);
	}

	// Storage product quote network card vendor.
	@dispatchAction(storageQuoteAction, 'setNetworkCardVendorList')
	@catchAPIError
	async getSpqNetworkCardVendorList (){
		return await fetchGet('/storage-quote-sources/network-card-vendors');
	}

	@dispatchAction(storageQuoteAction, 'setNetworkCardVendorList')
	async createSpqNetworkCardVendor (networkCardVendor){
		return await fetchPost('/storage-quote-sources/network-card-vendors', networkCardVendor);
	}

	@dispatchAction(storageQuoteAction, 'setNetworkCardVendorList')
	async editSpqNetworkCardVendor (networkCardVendor){
		return await fetchPut(`/storage-quote-sources/network-card-vendors/${networkCardVendor.id}`, networkCardVendor);
	}

	@dispatchAction(storageQuoteAction, 'setNetworkCardVendorList')
	async deleteSpqNetworkCardVendor (networkCardVendor){
		return await fetchDelete(`/storage-quote-sources/network-card-vendors/${networkCardVendor.id}`);
	}

	// Storage product quote network card.
	@dispatchAction(storageQuoteAction, 'setNetworkCardList')
	@catchAPIError
	async getSpqNetworkCardList (){
		return await fetchGet('/storage-quote-sources/network-cards');
	}

	@dispatchAction(storageQuoteAction, 'setNetworkCardList')
	async createSpqNetworkCard (networkCard){
		return await fetchPost('/storage-quote-sources/network-cards', networkCard);
	}

	@dispatchAction(storageQuoteAction, 'setNetworkCardList')
	async editSpqNetworkCard (networkCard){
		return await fetchPut(`/storage-quote-sources/network-cards/${networkCard.id}`, networkCard);
	}

	@dispatchAction(storageQuoteAction, 'setNetworkCardList')
	async deleteSpqNetworkCard (networkCard){
		return await fetchDelete(`/storage-quote-sources/network-cards/${networkCard.id}`);
	}

	// Storage product quote function.
	@dispatchAction(storageQuoteAction, 'setFunctionList')
	@catchAPIError
	async getSpqFunctionList (){
		return await fetchGet('/storage-quote-sources/functions');
	}

	@dispatchAction(storageQuoteAction, 'setFunctionList')
	async createSpqFunction (fuc){
		return await fetchPost('/storage-quote-sources/functions', fuc);
	}

	@dispatchAction(storageQuoteAction, 'setFunctionList')
	async editSpqFunction (fuc){
		return await fetchPut(`/storage-quote-sources/functions/${fuc.id}`, fuc);
	}

	@dispatchAction(storageQuoteAction, 'setFunctionList')
	async deleteSpqFunction (fuc){
		return await fetchDelete(`/storage-quote-sources/functions/${fuc.id}`);
	}

	// Storage product quote consumable.
	@dispatchAction(storageQuoteAction, 'setConsumableList')
	@catchAPIError
	async getSpqConsumableList (){
		return await fetchGet('/storage-quote-sources/consumables');
	}

	@dispatchAction(storageQuoteAction, 'setConsumableList')
	async createSpqConsumable (consumable){
		return await fetchPost('/storage-quote-sources/consumables', consumable);
	}

	@dispatchAction(storageQuoteAction, 'setConsumableList')
	async editSpqConsumable (consumable){
		return await fetchPut(`/storage-quote-sources/consumables/${consumable.id}`, consumable);
	}

	@dispatchAction(storageQuoteAction, 'setConsumableList')
	async deleteSpqConsumable (consumable){
		return await fetchDelete(`/storage-quote-sources/consumables/${consumable.id}`);
	}

	// Storage product quote pricing formula.
	@dispatchAction(storageQuoteAction, 'setPricingFormulaList')
	@catchAPIError
	async getSpqPricingFormulaList (){
		return await fetchGet('/storage-quote-sources/pricing-formulas');
	}

	@dispatchAction(storageQuoteAction, 'setPricingFormulaList')
	async createSpqPricingFormula (pricingFormula){
		return await fetchPost('/storage-quote-sources/pricing-formulas', pricingFormula);
	}

	@dispatchAction(storageQuoteAction, 'setPricingFormulaList')
	async editSpqPricingFormula (pricingFormula){
		return await fetchPut(`/storage-quote-sources/pricing-formulas/${pricingFormula.id}`, pricingFormula);
	}

	@dispatchAction(storageQuoteAction, 'setPricingFormulaList')
	async deleteSpqPricingFormula (pricingFormula){
		return await fetchDelete(`/storage-quote-sources/pricing-formulas/${pricingFormula.id}`);
	}

	// Get all storage product quote sources.
	@dispatchAction(storageQuoteAction, 'setAllQuoteSources')
	async getAllSpqSources () {
		return await fetchGet('/storage-quote-sources');
	}

	// Storage product quote apply approval.
	@dispatchAction(storageQuoteAction, 'setQuoteDiscountApplyList')
	@catchAPIError
	async getSpqDiscountApplyList (apply, approval){
		return await fetchGet('/storage-quote-discount-approvals', approval);
	}

	@dispatchAction(storageQuoteAction, 'setQuoteDiscountApplyList')
	async passSpqDiscountApply (apply, approval){
		return await fetchPut(`/storage-quote-discount-approvals/${apply.id}`, approval);
	}

	@dispatchAction(storageQuoteAction, 'setQuoteDiscountApplyList')
	async rejectDiscountApply (apply, approval){
		return await fetchPut(`/storage-quote-discount-approvals/${apply.id}`, approval);
	}

	@dispatchAction(storageQuoteAction, 'setQuoteDiscountApplyList')
	async deleteSpqDiscountApply (apply){
		return await fetchDelete(`/storage-quote-discount-approvals/${apply.id}`);
	}

	@dispatchAction(storageQuoteAction, 'setQuoteDiscountApplyList')
	async batchDeleteSpqDiscountApply (ids){
		return await fetchDelete('/storage-quote-discount-approvals/batch', ids);
	}

	// Certificate.
	@dispatchAction(productAction, 'setCertificateList')
	@catchAPIError
	async getCertificateList (){
		return await fetchGet('/certificates');
	}

	@dispatchAction(productAction, 'setCertificateList')
	async createCertificate (certificate){
		return await fetchPost('/certificates', certificate);
	}

	@dispatchAction(productAction, 'setCertificateList')
	async editCertificate (certificate){
		return await fetchPut(`/certificates/${certificate.id}`, certificate);
	}

	@dispatchAction(productAction, 'setCertificateList')
	async deleteCertificate (certificate){
		return await fetchDelete(`/certificates/${certificate.id}`);
	}

	// Recruitment.
	// Job.
	@dispatchAction(recruitmentAction, 'setJobList')
	@catchAPIError
	async getJobList (){
		return await fetchGet('/jobs');
	}

	@dispatchAction(recruitmentAction, 'setJobList')
	async createJob (job){
		return await fetchPost('/jobs', job);
	}

	@dispatchAction(recruitmentAction, 'setJobList')
	async editJob (job){
		return await fetchPut(`/jobs/${job.id}`, job);
	}

	@dispatchAction(recruitmentAction, 'setJobList')
	async showJob (job){
		return await fetchPut(`/jobs/${job.id}`, job);
	}

	@dispatchAction(recruitmentAction, 'setJobList')
	async hideJob (job){
		return await fetchPut(`/jobs/${job.id}`, job);
	}

	@dispatchAction(recruitmentAction, 'setJobList')
	async deleteJob (job){
		return await fetchDelete(`/jobs/${job.id}`);
	}

	// Job Application.
	@dispatchAction(recruitmentAction, 'setJobApplicationList')
	@catchAPIError
	async getJobApplicationList (){
		return await fetchGet('/resumes');
	}

	@dispatchAction(recruitmentAction, 'setJobApplicationList')
	async handleJobApplication (jobApplication){
		return await fetchPut(`/resumes/${jobApplication.id}`, jobApplication);
	}

	@dispatchAction(recruitmentAction, 'setJobApplicationList')
	async deleteJobApplication (jobApplication){
		return await fetchDelete(`/resumes/${jobApplication.id}`);
	}

	@dispatchAction(logAction, 'setOperationLogList')
	async getOperationLogList (){
		return await fetchGet('/operation-logs');
	}
}

export default new HttpCaller();