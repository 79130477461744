export const recruitmentActionTypes = {
	SET_JOB_LIST: 'RECRUITMENT@SET_JOB_LIST',
	SET_JOB_APPLICATION_LIST: 'RECRUITMENT@SET_JOB_APPLICATION_LIST',
};

export default {
	setJobList: jobList => ({
		type: recruitmentActionTypes.SET_JOB_LIST,
		jobList
	}),

	setJobApplicationList: jobApplicationList => ({
		type: recruitmentActionTypes.SET_JOB_APPLICATION_LIST,
		jobApplicationList
	}),
};