import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useRenderChart} from '@hooks';
import cecharts from 'cecharts';
import {equals, generateLineChartOption, makeLineSeries, PropTypes} from '@utils';

function OrcaLineChart (props, ref){
	// Parse props.
    let {
    	// General.
    	title, legend = {}, color = [], tooltipFormatter = '',
		// Width, height and margin.
		width = 'calc(100% - 10px)', height = 'calc(100% - 20px)', x = 70, y = 20,
		// Y axis.
		yAxisLabelFormatter = '', yAxisUnit = '', yMin = null, yMax = null,
		// Label.
		labelTimeFormat, label = [],
		// Series.
		seriesArr,
		// Render mode.
		renderMode = 'async',
    } = props;

	// Internal states.
	const [option, setOption] = useState({
		title, legend, color, tooltipFormatter,
		width, height, x, y,
		yAxisUnit, yAxisLabelFormatter, yMin, yMax,
		labelTimeFormat, label,
		series: makeLineSeries(seriesArr),
		renderMode,
	});

    // Ref data.
	const seriesArrRef = useRef([]);
    const chartWrapperDOMRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const initialRendered = useRef(false);
    const idleCallbackID = useRef(null);

    const hideTooltipTimer = useRef(null);

    // Page actions.
    const resizeChart = useCallback(() => chartInstanceRef.current.resize(), []);

    // Memorized data.
	const lineSeriesMemo = useMemo(() => makeLineSeries(seriesArr), [seriesArr]);

	const isDataReadyMemo = useMemo(() => option.series[0].data.length > 0, [option]);

	const chartWrapperDOMStyleMemo = useMemo(() => ({
		display: 'inline-block',
		marginTop: '10px',
		width: option.width,
		height: option.height,
	}), [option.width, option.height]);

    // After rendering and re-rendering.
    useEffect(() => {
		if (!chartInstanceRef.current) {
			chartInstanceRef.current = cecharts.init(chartWrapperDOMRef.current);
		}
	}, []);

	// Whenever props value changes, seriesArr changes too verified by deep equal,
	// should trigger a option state change.
	useEffect(() => {
		if (!equals(seriesArrRef.current, seriesArr)) {
			seriesArrRef.current = seriesArr;
			setOption({
				title, legend, color, tooltipFormatter,
				width, height, x, y,
				yAxisUnit, yAxisLabelFormatter, yMin, yMax,
				labelTimeFormat, label,
				series: lineSeriesMemo,
				renderMode,
			});
		}
	}, [title, legend, color, tooltipFormatter, width, height, x, y, yAxisUnit, yAxisLabelFormatter, yMax, yMin, labelTimeFormat, label, seriesArr, renderMode, lineSeriesMemo]);

	// Listen window resize event.
    useEffect(() => {
        window.addEventListener('resize', resizeChart);

        return () => {
            window.removeEventListener('resize', resizeChart);
        };
    }, [resizeChart]);

    // If option is changed, render or re-render the chart.
	useRenderChart(option, isDataReadyMemo, generateLineChartOption, chartInstanceRef, initialRendered, idleCallbackID);

	// Events.
	const hideTooltip = useCallback(() => {
		chartInstanceRef.current.dispatchAction({
			type: 'hideTip',
		});
	}, []);

	const onChartMouseOut = useCallback(() => {
		// To fix a bug of cecharts: the tooltip may not hide when mouse leaving the
		// chart area too fast, we set a delay timer to force chart to hide it.
		hideTooltipTimer.current = setTimeout(() => hideTooltip(), 200);
	}, [hideTooltip]);

	// Exposed methods.
	useImperativeHandle(ref, () => ({
		selectLegend: (legend, type) => {
			chartInstanceRef.current.dispatchAction({
				name: legend,
				type: type,
			});
		},
		hideTooltip,
	}), [hideTooltip]);

	// When component unmount destroy chart instance to dispose its memory footprint,
	// to avoid memory leak and cancel the chart background tasks, saves some resources
	// for page performance improvement if chart does not do it by itself.
	useEffect(() => {
		return () => {
			chartInstanceRef.current = null;
			if (hideTooltipTimer.current) {
				window.clearTimeout(hideTooltipTimer.current);
			}
		};
	}, []);

    return (
        <div
			className="ocms-line-chart"
			style={chartWrapperDOMStyleMemo}
			ref={chartWrapperDOMRef}
			onMouseOut={onChartMouseOut}
		/>
    );
}

OrcaLineChart.propType = {
    title: PropTypes.string,
    legend: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	color: PropTypes.array,
    tooltipFormatter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    x: PropTypes.number,
    y: PropTypes.number,
    yAxisLabelFormatter: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    yAxisUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    yMin: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    yMax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    labelTimeFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    label: PropTypes.array.isRequired,
    seriesArr: PropTypes.array.isRequired,
    renderMode: PropTypes.string,
};

export default forwardRef(OrcaLineChart);

/*
		  	 <OrcaChart />
		      	  |
		   <ViewComponent />
				  |
	+-----------------------------+
	|		  OrcaChart           |
	|          |     |            |
	| chartHelper  useRenderChart |
	+-----------------------------+
	              |
	+-----------------------------+
	|		   echarts            |
	|			  |               |
	|		   z-render           |
	+-----------------------------+
				  |
		     HTML5 canvas
 */