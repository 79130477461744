const routes = {
    Root: '/',

    // Sign In.
    SignIn: '/sign-in',

    // Main.
    Main: '/cms',

    // Dashboard.
    Dashboard: '/dashboard',

    // News.
    FirmNews: '/firm-news',

    // User.
    CMSUser: '/cms-user',
    SiteUser: '/site-user',

    // Business.
    Consultation: '/consultation',
    TrialApplication: '/trial-application',

    // Product and certificate.
    Product: '/product',
    Document: '/document',
    AfterSalesCase: '/after-sales-case',
    ImageGallery: '/image-gallery',
    Guarantee: '/guarantee',
    Certificate: '/certificate',

    // Storage quotation.
    Spq: '/storage-product-quote',

    // Storage quotation source info input.
    SpqProduct: '/spq-product',
    SpqCpu: '/spq-cpu',
    SpqMemory: '/spq-memory',
    SpqDisk: '/spq-disk',
    SpqNetworkCard: '/spq-network-card',
    SpqFunction: '/spq-function',
    SpqRedundant: '/spq-redundant',
    SpqConsumable: '/spq-consumable',
    SpqPricingFormula: '/spq-pricing-formula',

    // Storage quotation discount apply approval.
    SpqDiscountApproval: '/spq-discount-approval',

    // Recruitment.
    Job: '/job',
    JobApplication: '/job-application',

    // Log.
    OperationLog:  '/operation-log',

    // Cloud.
    Cloud: '/cloud',
};

export default routes;

export const routeToMenu = {
    Dashboard: [routes.Dashboard],
    News: [routes.FirmNews],
    User: [routes.CMSUser, routes.SiteUser],
    Business: [routes.Consultation, routes.TrialApplication],
    Product: [routes.Product, routes.Document, routes.AfterSalesCase, routes.ImageGallery, routes.Guarantee, routes.Certificate],
    StorageQuote: [routes.Spq],
    StorageQuoteSource: [routes.SpqProduct, routes.SpqCpu, routes.SpqMemory, routes.SpqDisk, routes.SpqNetworkCard, routes.SpqFunction, routes.SpqRedundant, routes.SpqConsumable, routes.SpqPricingFormula],
    SpqDiscountApproval: [routes.SpqDiscountApproval],
    Certificate: [routes.Certificate],
    Recruitment: [routes.Job, routes.JobApplication],
    Log: [routes.OperationLog],
    Cloud: [routes.Cloud],
};

export const siteRoutes = {
    // Only for access statistics from official site, not CMS.

    // Common.
    SignUp: '/sign-up',
    SignIn: '/sign-in',
    ResetPassword: '/reset-password',
    ApplyTrial: '/apply-trial',
    ApplyJob: '/apply-job',

    // Home.
    Home: '/home',

    // Product.
    UniIO: '/uniio',
    OrcaBD: '/orcabd',
    OrcaFS: '/orcafs',
    IOT: '/iot',

    // Solutions.
	// Storage solution.
	// General category.
	BigData: '/big-data',
	HighPerformanceComputing: '/high-performance-computing',
	CloudComputingPrivateCloud: '/cloud-computing-private-cloud',
	CloudNativeContainerStorage: '/cloud-native-container-storage',
	DataBackup: '/data-backup',
	// Industry category.
	NewEnergyVehicle: '/new-energy-vehicle',
	IntelligentCampus: '/intelligent-campus',
	IntelligentSecurity: '/intelligent-security',
	AI: '/ai',
	// IoT storage.
	// Intelligent police category.
	BrightProject: '/bright-project',
	MajorEventSecurity: '/major-event-security',
	// Intelligent community category.
	IntelligentCommunity: '/intelligent-community',
	IntelligentCommunitySecurity: '/intelligent-community-security',
	IntelligentElevator: '/intelligent-elevator',
	HighAltitudeParabolic: '/high-altitude-parabolic',
	// Intelligent park category.
	IntelligentPark: '/intelligent-park',
	// Intelligent scenic spot category.
	IntelligentScenicSpot: '/intelligent-scenic-spot',
	AllAreaTourism: '/all-area-tourism',
	// Carbon neutrality category.
	CarbonNeutrality: '/carbon-neutrality',
	// Intelligent garden category.
	IntelligentGarden: '/intelligent-garden',
	// Emergency category.
	EmergencyCommand: '/emergency-command',
	SafetyProductionEmergency: '/safety-production-emergency',
	// General category.
	IoTPlatform: '/iot-platform',

    // Firm News.
    FirmNews: '/firm-news',
    FirmNewsDetail: '/firm-news-detail',

    // Technical Support.
    Document: '/document',
    DocumentDetail: '/document-detail',
    AfterSalesCase: '/after-sales-case',
    ImageGallery: '/image-gallery',
    ImageGalleryDetail: '/image-gallery-detail',
    Guarantee: '/guarantee',

    // About.
    AboutUs: '/about-us',
    Leadership: '/leadership',
    Honor: '/honor',
    Recruitment: '/recruitment',
    ContactUs: '/contact-us',
};