import {CronJob} from 'cron';
import store from '@store';
import httpCaller from '@http/caller';
import routes from '@routes';

class CMSCronJob {
    constructor (){
        this.cronJob = null;
        this.enablePlaybook = false;
        this.initCronJob();
    }

    initCronJob (){
        // Execute every 10 seconds.
        this.cronJob = new CronJob('*/10 * * * * *', this.executePlaybook.bind(this), null, true);
    }

    runPlaybook (){
        this.enablePlaybook = true;
    }

    pausePlaybook (){
        this.enablePlaybook = false;
    }

    async executePlaybook (){
        if (this.enablePlaybook) {
            const routerHash = window.location.hash.replace('#', '');
            const main = routes.Main;

            // Load data in background depends on the router.
            switch (routerHash) {
                case main + routes.Dashboard:
		            httpCaller.getDashboardData({stage: store.getState().dashboard.statisticsPeriod});
                    break;

                case main + routes.SiteUser:
		            httpCaller.getSiteUserList();
                    break;

                case main + routes.FirmNews:
		            httpCaller.getFirmNewsList();
                    break;

                case main + routes.Consultation:
		            httpCaller.getConsultationList();
                    break;

                case main + routes.TrialApplication:
		            httpCaller.getTrialApplicationList();
                    break;

                case main + routes.Product:
		            httpCaller.getProductList();
                    break;

                case main + routes.Document:
		            httpCaller.getDocumentList();
                    break;

                case main + routes.ImageGallery:
                    httpCaller.getImageGalleryList();
                    break;

                case main + routes.Guarantee:
		            httpCaller.getGuaranteeList();
                    break;

                case main + routes.Spq:
                    httpCaller.getSpqList();
                    break;

                case main + routes.SpqProduct:
                    httpCaller.getSpqList();
                    httpCaller.getSpqProductList();
                    httpCaller.getSpqProductModelList();
                    httpCaller.getSpqCpuVendorList();
                    httpCaller.getSpqCpuList();
                    httpCaller.getSpqMemoryList();
                    httpCaller.getSpqDiskVendorList();
                    httpCaller.getSpqDiskList();
                    httpCaller.getSpqNetworkCardList();
                    httpCaller.getSpqFunctionList();
                    httpCaller.getSpqConsumableList();
                    break;

                case main + routes.SpqCpu:
                    httpCaller.getSpqCpuVendorList();
                    httpCaller.getSpqCpuList();
                    break;

                case main + routes.SpqMemory:
                    httpCaller.getSpqMemoryList();
                    break;

                case main + routes.SpqDisk:
                    httpCaller.getSpqDiskVendorList();
                    httpCaller.getSpqDiskList();
                    break;

                case main + routes.SpqNetworkCard:
                    httpCaller.getSpqNetworkCardList();
                    break;

                case main + routes.SpqFunction:
                    httpCaller.getSpqFunctionList();
                    break;

                case main + routes.SpqConsumable:
                    httpCaller.getSpqConsumableList();
                    break;

                case main + routes.SpqPricingFormula:
                    httpCaller.getSpqPricingFormulaList();
                    break;

                case main + routes.SpqDiscountApproval:
                    httpCaller.getSpqDiscountApplyList();
                    break;

                case main + routes.Certificate:
		            httpCaller.getCertificateList();
                    break;

                case main + routes.Job:
		            httpCaller.getJobList();
                    break;

                case main + routes.JobApplication:
		            httpCaller.getJobApplicationList();
                    break;

                case main + routes.OperationLog:
                    httpCaller.getOperationLogList();
                    break;

                default:
                    return;
            }
        }
    }

    destroyCronJob (){
        this.enablePlaybook = false;
        this.cronJob = null;
    }
}

const cronJob = new CMSCronJob();

export default cronJob;