export const storageQuoteActionTypes = {
	SET_QUOTE_LIST: 'STORAGE_QUOTE@SET_QUOTE_LIST',
	SET_PRODUCT_LIST: 'STORAGE_QUOTE@SET_PRODUCT_LIST',
	SET_PRODUCT_MODEL_LIST: 'STORAGE_QUOTE@SET_PRODUCT_MODEL_LIST',
	SET_CPU_VENDOR_LIST: 'STORAGE_QUOTE@SET_CPU_VENDOR_LIST',
	SET_CPU_LIST: 'STORAGE_QUOTE@SET_CPU_LIST',
	SET_MEMORY_LIST: 'STORAGE_QUOTE@SET_MEMORY_LIST',
	SET_DISK_VENDOR_LIST: 'STORAGE_QUOTE@SET_DISK_VENDOR_LIST',
	SET_DISK_LIST: 'STORAGE_QUOTE@SET_DISK_LIST',
	SET_NETWORK_CARD_VENDOR_LIST: 'STORAGE_QUOTE@SET_NETWORK_CARD_VENDOR_LIST',
	SET_NETWORK_CARD_LIST: 'STORAGE_QUOTE@SET_NETWORK_CARD_LIST',
	SET_FUNCTION_LIST: 'STORAGE_QUOTE@SET_FUNCTION_LIST',
	SET_CONSUMABLE_LIST: 'STORAGE_QUOTE@SET_CONSUMABLE_LIST',
	SET_PRICING_FORMULA_LIST: 'STORAGE_QUOTE@SET_PRICING_FORMULA_LIST',
	SET_QUOTE_SOURCE: 'STORAGE_QUOTE@SET_QUOTE_SOURCE',
	SET_QUOTE_DISCOUNT_APPLY_LIST: 'STORAGE_QUOTE@SET_QUOTE_DISCOUNT_APPLY_LIST',
};

export default {
	setQuoteList: quoteList => ({
		type: storageQuoteActionTypes.SET_QUOTE_LIST,
		quoteList
	}),

	setProductList: productList => ({
		type: storageQuoteActionTypes.SET_PRODUCT_LIST,
		productList
	}),

	setProductModelList: productModelList => ({
		type: storageQuoteActionTypes.SET_PRODUCT_MODEL_LIST,
		productModelList
	}),

	setCpuVendorList: cpuVendorList => ({
		type: storageQuoteActionTypes.SET_CPU_VENDOR_LIST,
		cpuVendorList
	}),

	setCpuList: cpuList => ({
		type: storageQuoteActionTypes.SET_CPU_LIST,
		cpuList
	}),

	setMemoryList: memoryList => ({
		type: storageQuoteActionTypes.SET_MEMORY_LIST,
		memoryList
	}),

	setDiskVendorList: diskVendorList => ({
		type: storageQuoteActionTypes.SET_DISK_VENDOR_LIST,
		diskVendorList
	}),

	setDiskList: diskList => ({
		type: storageQuoteActionTypes.SET_DISK_LIST,
		diskList
	}),

	setNetworkCardVendorList: networkCardVendorList => ({
		type: storageQuoteActionTypes.SET_NETWORK_CARD_VENDOR_LIST,
		networkCardVendorList
	}),

	setNetworkCardList: networkCardList => ({
		type: storageQuoteActionTypes.SET_NETWORK_CARD_LIST,
		networkCardList
	}),

	setFunctionList: functionList => ({
		type: storageQuoteActionTypes.SET_FUNCTION_LIST,
		functionList
	}),

	setConsumableList: consumableList => ({
		type: storageQuoteActionTypes.SET_CONSUMABLE_LIST,
		consumableList
	}),

	setPricingFormulaList: pricingFormulaList => ({
		type: storageQuoteActionTypes.SET_PRICING_FORMULA_LIST,
		pricingFormulaList
	}),

	setAllQuoteSources: quoteSource => ({
		type: storageQuoteActionTypes.SET_QUOTE_SOURCE,
		quoteSource
	}),

	setQuoteDiscountApplyList: quoteDiscountApplyList => ({
		type: storageQuoteActionTypes.SET_QUOTE_DISCOUNT_APPLY_LIST,
		quoteDiscountApplyList
	}),
};