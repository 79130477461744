'use strict';

// The polyfill provided by babel core-js should not placed here,
// because it will be automatically transplied based on the environment
// instead of import all core-js. For example:
// In
// import "core-js";
//
// Out
// import "core-js/modules/es.string.pad-start";
// import "core-js/modules/es.string.pad-end";
//
// Place it here, it will be inject through webpack entry, which
// will make this transplation failed.

// fetch() polyfill for making API calls.
require('whatwg-fetch');